import { LatLng } from "leaflet";

export enum RoadType
{
  Allowed,
  Prohibited
}

export enum MapDrawingColors
{
  RoadAllowed = "green",
  RoadProhibited = "purple",
  Polyline = "black"
}

export interface IRoad
{
    identifier: string;
    coordinates: LatLng[];
    type: RoadType;
    isEditable: boolean;
}