import { CommandBar, ConstrainMode, DetailsListLayoutMode, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList, Selection, IObjectWithKey, IColumn, Pivot, PivotItem, SearchBox } from "@fluentui/react";
import * as React from "react";
import { ICreateOrUpdateSupplierDTO, ICreateOrUpdateUserDTO } from "../../models";
import { IUserService, Services, UserServiceProvider } from "../../services";
import UserPanel from './UserPanel/UserPanel';
import SupplierPanel from './SupplierPanel/SupplierPanel';
import Utilities from "../../helpers/Utilities";
import styles from './UserManagement.module.scss';
import App from "../../App";

interface IUserManagementProps
{
    
}

interface IUserManagementState
{
    isLoading: boolean;
    users: ICreateOrUpdateUserDTO[];    
    columns_users: IColumn[];
    selectedUser?: ICreateOrUpdateUserDTO;    
    showUserPanel: boolean;

    suppliers: ICreateOrUpdateSupplierDTO[];
    columns_suppliers: IColumn[];
    selectedSupplier?: ICreateOrUpdateSupplierDTO;
    showSupplierPanel: boolean;

    columns_DLs: IColumn[];
    columns_Admins: IColumn[];
}

export default class UserManagement extends React.Component<IUserManagementProps, IUserManagementState> {
    private _allUsers: ICreateOrUpdateUserDTO[] = [] as ICreateOrUpdateUserDTO[];
    private _allSuppliers: ICreateOrUpdateSupplierDTO[] = [] as ICreateOrUpdateSupplierDTO[];
    private userService : IUserService;
    private _selection: Selection;
    private _selection_Suppliers: Selection;

    constructor(props: IUserManagementProps) {
        super(props);

        this.userService = Services.UserService.Initialize(new UserServiceProvider());

        this._selection = new Selection({
            onSelectionChanged: () => {                 
                this.setState({ selectedUser: (this._selection.getSelection() as IObjectWithKey | any)[0] as ICreateOrUpdateUserDTO});
              }
        });

        this._selection_Suppliers = new Selection({
            onSelectionChanged: () => {                 
                this.setState({ selectedSupplier: (this._selection_Suppliers.getSelection() as IObjectWithKey | any)[0] as ICreateOrUpdateSupplierDTO});
              }
        });

        const columns_users: IColumn[] = [        
            {
                key: 'supplierName',
                name: 'Leverandør',
                fieldName: 'supplierName',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClickUsers.bind(this),
              },
            {
              key: 'name',
              name: 'Navn',
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 200,
              isResizable: true,
              onColumnClick: this._onColumnClickUsers.bind(this),
            },
            {
                key: 'email',
                name: 'E-mail',
                fieldName: 'email',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClickUsers.bind(this),
            }
        ];

        const columns_suppliers: IColumn[] = [
            {
                key: 'regionName',
                name: 'Region',
                fieldName: 'regionName',
                minWidth: 200,
                maxWidth: 200,
                onColumnClick: this._onColumnClickSupliers.bind(this),
            },
            {
              key: 'name',
              name: 'Navn',
              fieldName: 'name',
              minWidth: 300,
              maxWidth: 300,
              isResizable: true,
              onColumnClick: this._onColumnClickSupliers.bind(this),
            },
            {
                key: 'projectNo',
                name: 'Projektnummer',
                fieldName: 'projectNo',
                minWidth: 200,
                maxWidth: 200,
                onColumnClick: this._onColumnClickSupliers.bind(this),
            }
        ];

        const columns_DLs: IColumn[] = [            
            {
              key: 'name',
              name: 'Navn',
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 200,
              isResizable: true,
              onColumnClick: this._onColumnClickDLs.bind(this),
            },
            {
                key: 'email',
                name: 'E-mail',
                fieldName: 'email',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClickDLs.bind(this),
            },
            {
                key: 'workPhone',
                name: 'Telefonnummer',
                fieldName: 'workPhone',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                onColumnClick: this._onColumnClickDLs.bind(this),
            },
            {
                key: 'regionName',
                name: 'Region',
                fieldName: 'regionName',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClickDLs.bind(this)                
            } 
        ];

        const columns_Admins: IColumn[] = [            
            {
              key: 'name',
              name: 'Navn',
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 200,
              isResizable: true,
              onColumnClick: this._onColumnClickAdmins.bind(this),
            },
            {
                key: 'email',
                name: 'E-mail',
                fieldName: 'email',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClickAdmins.bind(this),
            },
            {
                key: 'workPhone',
                name: 'Telefonnummer',
                fieldName: 'workPhone',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                onColumnClick: this._onColumnClickAdmins.bind(this),
            }
        ];

        this.state = {
            isLoading: true,
            showUserPanel: false,
            showSupplierPanel: false,
            columns_users: columns_users,
            columns_suppliers: columns_suppliers,            
            users: [],
            suppliers: [],
            columns_DLs: columns_DLs,
            columns_Admins: columns_Admins
        };
        
        this.getData("Suppliers");
    }

    public render(): React.ReactElement<IUserManagementProps> {
        const CommandBar_Users: ICommandBarItemProps[] = [
            {
                key: 'newUser',
                text: 'Ny bruger',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({selectedUser: undefined, showUserPanel: true }, () => { this._selection.setAllSelected(false); }) }      
            },
            {
                key: 'editUser',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({showUserPanel: true}) },
                disabled: !this.state.selectedUser
            }
        ];

        const CommandBar_Suppliers: ICommandBarItemProps[] = [
            {
                key: 'newUser',
                text: 'Ny leverandør',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({selectedSupplier: undefined, showSupplierPanel: true }, () => { this._selection_Suppliers.setAllSelected(false); }) }      
            },
            {
                key: 'editUser',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({showSupplierPanel: true}) },
                disabled: !this.state.selectedSupplier
            }
        ];

        return (<div className={styles.userManagement}>
                    <h3>Brugeradministration</h3>
                    <Pivot onLinkClick={(item?: PivotItem) => { this.getData(item?.props.itemKey) }} >                        
                        <PivotItem itemKey={"Suppliers"} headerText={`Leverandører`}>
                            <div style={{margin:"20px"}}>
                            <CommandBar items={CommandBar_Suppliers} styles={{root: {paddingLeft:"0px"}}} farItems={this.CreateFarMenuItems()} />
                                <ShimmeredDetailsList
                                    items={this.state.suppliers.filter(x => x.internal === false)}
                                    columns={this.state.columns_suppliers}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection_Suppliers}
                                    onItemInvoked={(item: ICreateOrUpdateSupplierDTO) => { this.setState({showSupplierPanel: true}); }}
                                    constrainMode={ConstrainMode.unconstrained}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    enableShimmer={this.state.isLoading}
                                    setKey="set"
                                />
                                {this.state.showSupplierPanel && <SupplierPanel selectedItem={this.state.selectedSupplier} onChanged={(updatedItem: ICreateOrUpdateSupplierDTO) => { this.getData("Suppliers"); this.setState({selectedSupplier: updatedItem}); }} onDismiss={() => { this.setState({showSupplierPanel: false}); } } />}          
                            </div>
                        </PivotItem>
                        <PivotItem itemKey={"InternalSuppliers"} headerText={`Interne leverandører`}>
                            <div style={{margin:"20px"}}>
                            <CommandBar items={CommandBar_Suppliers} styles={{root: {paddingLeft:"0px"}}} farItems={this.CreateFarMenuItems()} />
                                <ShimmeredDetailsList
                                    items={this.state.suppliers.filter(x => x.internal === true)}
                                    columns={this.state.columns_suppliers}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection_Suppliers}
                                    onItemInvoked={(item: ICreateOrUpdateSupplierDTO) => { this.setState({showSupplierPanel: true}); }}
                                    constrainMode={ConstrainMode.unconstrained}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    enableShimmer={this.state.isLoading}
                                    setKey="set"
                                />
                                {this.state.showSupplierPanel && <SupplierPanel selectedItem={this.state.selectedSupplier} onChanged={(updatedItem: ICreateOrUpdateSupplierDTO) => { this.getData("Suppliers"); this.setState({selectedSupplier: updatedItem}); }} onDismiss={() => { this.setState({showSupplierPanel: false}); } } />}          
                            </div>
                        </PivotItem>
                        { App.IsUserInRole("Admin") && 
                            <PivotItem itemKey={"DLs"} headerText={`Driftsledere`}>
                                <div style={{margin:"20px"}}>
                                    <CommandBar items={CommandBar_Users} styles={{root: {paddingLeft:"0px"}}} />
                                    <ShimmeredDetailsList
                                        items={this.state.users.filter(x => x.roles.some(g => ["DL"].includes(g.name)))}
                                        columns={this.state.columns_DLs}
                                        selectionMode={SelectionMode.single}
                                        selection={this._selection}
                                        onItemInvoked={(item: ICreateOrUpdateUserDTO) => { this.setState({showUserPanel: true}); }}
                                        constrainMode={ConstrainMode.unconstrained}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        enableShimmer={this.state.isLoading}
                                        setKey="set"
                                    />
                                    {this.state.showUserPanel && <UserPanel selectedUser={this.state.selectedUser} onChanged={(user: ICreateOrUpdateUserDTO) => { this.getData("Users"); this.setState({selectedUser: user}); }} onDismiss={() => { this.setState({showUserPanel: false}); } } />}
                                </div>
                            </PivotItem>
                        }
                        { App.IsUserInRole("Admin") && 
                            <PivotItem itemKey={"Finance"} headerText={`Økonomimedarbejdere`}>
                                <div style={{margin:"20px"}}>
                                    <CommandBar items={CommandBar_Users} styles={{root: {paddingLeft:"0px"}}} />
                                    <ShimmeredDetailsList
                                        items={this.state.users.filter(x => x.roles.some(g => ["Finance"].includes(g.name)))}
                                        columns={this.state.columns_Admins}
                                        selectionMode={SelectionMode.single}
                                        selection={this._selection}
                                        onItemInvoked={(item: ICreateOrUpdateUserDTO) => { this.setState({showUserPanel: true}); }}
                                        constrainMode={ConstrainMode.unconstrained}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        enableShimmer={this.state.isLoading}
                                        setKey="set"
                                    />
                                    {this.state.showUserPanel && <UserPanel selectedUser={this.state.selectedUser} onChanged={(user: ICreateOrUpdateUserDTO) => { this.getData("Users"); this.setState({selectedUser: user}); }} onDismiss={() => { this.setState({showUserPanel: false}); } } />}
                                </div>
                            </PivotItem>
                        }
                        { App.IsUserInRole("Admin") && 
                            <PivotItem itemKey={"Admins"} headerText={`Administratorer`}>
                                <div style={{margin:"20px"}}>
                                    <CommandBar items={CommandBar_Users} styles={{root: {paddingLeft:"0px"}}} />
                                    <ShimmeredDetailsList
                                        items={this.state.users.filter(x => x.roles.some(g => ["Admin"].includes(g.name)))}
                                        columns={this.state.columns_Admins}
                                        selectionMode={SelectionMode.single}
                                        selection={this._selection}
                                        onItemInvoked={(item: ICreateOrUpdateUserDTO) => { this.setState({showUserPanel: true}); }}
                                        constrainMode={ConstrainMode.unconstrained}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        enableShimmer={this.state.isLoading}
                                        setKey="set"
                                    />
                                    {this.state.showUserPanel && <UserPanel selectedUser={this.state.selectedUser} onChanged={(user: ICreateOrUpdateUserDTO) => { this.getData("Users"); this.setState({selectedUser: user}); }} onDismiss={() => { this.setState({showUserPanel: false}); } } />}
                                </div>
                            </PivotItem>
                        }
                    </Pivot>                             
               </div>);
    }

    private onSearchUsers(searchTerm: string | undefined): void
    {
        this.setState({ users: searchTerm && searchTerm != "" ? this.filterUsers(searchTerm) : this._allUsers });
    }

    private filterUsers(searchTerm: string) : ICreateOrUpdateUserDTO[]
    {
        return this._allUsers.filter(i => 
        (i.name + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
        (i.email + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.supplier?.name + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1      
        );
    }

    private _onColumnClickUsers(ev: React.MouseEvent<HTMLElement>, column: IColumn)
    {    
        const newColumns: IColumn[] = this.state.columns_users.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
        });
        const newItems = Utilities.CopyAndSort(this.state.users.filter(x => x.roles.some(g => ["Supplier"].includes(g.name))), currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns_users: newColumns,
            users: newItems
        });
    }

    private CreateFarMenuItems()
    {
        return [
        {        
            key:"search",
            onRender:() => <SearchBox 
            placeholder="Søg efter leverandør ..." 
            className={styles.searchBox} 
            onSearch={(newValue) => { this.onSearch(newValue); } } 
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { this.onSearch(newValue); }}
            onClear={ev => { this.onSearch(""); }}
            />
        }
        ];
    }

    private onSearch(searchTerm: string | undefined): void
    {
        this.setState({ suppliers: searchTerm && searchTerm != "" ? this.filter(searchTerm) : this._allSuppliers });
    }

    private filter(searchTerm: string) : ICreateOrUpdateSupplierDTO[]
    {
        return this._allSuppliers.filter(i => 
        (i.region?.name + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.name + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
        (i.projectNo + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1      
        );
    }

    private _onColumnClickSupliers(ev: React.MouseEvent<HTMLElement>, column: IColumn)
    {    
        const newColumns: IColumn[] = this.state.columns_suppliers.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
        });
        const newItems = Utilities.CopyAndSort(this.state.suppliers, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns_suppliers: newColumns,
            suppliers: newItems
        });
    }

    private _onColumnClickDLs(ev: React.MouseEvent<HTMLElement>, column: IColumn)
    {    
        const newColumns: IColumn[] = this.state.columns_DLs.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
        });
        const newItems = Utilities.CopyAndSort(this.state.users, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns_DLs: newColumns,
            users: newItems
        });
    }

    private _onColumnClickAdmins(ev: React.MouseEvent<HTMLElement>, column: IColumn)
    {    
        const newColumns: IColumn[] = this.state.columns_Admins.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
        });
        const newItems = Utilities.CopyAndSort(this.state.users, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns_Admins: newColumns,
            users: newItems
        });
    }

    private async getData(pivotItemKey?: string)
    {        
        switch(pivotItemKey)
        {
            case "Users":
                {
                    this._selection.setAllSelected(false);

                    this._allUsers = await this.userService.GetUsers();

                    this.setState(
                        {
                            users: this._allUsers, 
                            isLoading:false, 
                            showUserPanel: false
                        }
                    );
                }
                break;            
            case "Suppliers":
            case "InternalSuppliers":                
                this._selection_Suppliers.setAllSelected(false);

                this._allUsers = await this.userService.GetUsers();
                this._allSuppliers = await this.userService.GetSuppliers();

                for(let s of this._allSuppliers)
                {
                    s.users = this._allUsers.filter(d => d.supplier?.id == s.id);
                }
        
                this.setState(
                    {
                        users: this._allUsers,
                        suppliers: this._allSuppliers, 
                        isLoading:false, 
                        showSupplierPanel: false
                    }
                );
                break;
        }       
    }    
}