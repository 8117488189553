import React from 'react';
import { LeafletConsumer } from 'react-leaflet';
import { CachedTileLayer } from '@yaga/leaflet-cached-tile-layer';
 
const MapCacheLayer= () =>{
    return <LeafletConsumer>
        {
            context => {
                new CachedTileLayer("https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/wmts?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A&layer=orto_foraar_wmts&style=default&tilematrixset=KortforsyningTilingDK&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/jpeg&TileMatrix={z}&TileCol={x}&TileRow={y}", {
                    attribution: `Orto`,
                    databaseName: "tile-cache-data-orto_foraar_wmts", // optional
                    databaseVersion: 1, // optional
                    objectStoreName: "orto_foraar_wmts", // optional
                    crawlDelay: 500, // optional
                    maxAge: 1000 * 60 * 60 * 24 * 7, // optional
                }).addTo(context.map as any)

                return <div />
            }
        }
    </LeafletConsumer>
 
}
 
export default MapCacheLayer;