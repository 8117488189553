import * as React from "react";

export interface IVisibilityControlProps
{
    name: string;
    visible: boolean;
    className?: string;
}

export class VisibilityControl extends React.Component<IVisibilityControlProps, {}> {
    public render(): React.ReactElement<IVisibilityControlProps> {
        if(!this.props.visible)
        {
            return <React.Fragment />;
        }

        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}