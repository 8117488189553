import * as React from 'react';
import { Callout, CommandBar, DefaultButton, Dialog, DialogFooter, DirectionalHint, Dropdown, ICommandBarItemProps, Icon, IconButton, IDropdownOption, Link, List, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { IAssignmentDTO, IContainerSpot, ILayer, IMapData, IRoad, IStackMarker, ITextArea, IWorkingArea, MapDrawingColors, RoadType, WorkingAreaType } from '../../../models';
import { Map, TileLayer, Marker, Popup, LayersControl, FeatureGroup, Polyline, WMSTileLayer, Polygon, Tooltip, ScaleControl } from 'react-leaflet';
import StackDialog from './Dialogs/StackDialog';
import WorkingAreaDialog from './Dialogs/WorkingAreaDialog';
import TextAreaDialog from './Dialogs/TextAreaDialog';
import L, { Direction, LatLng, LatLngTuple } from "leaflet";
import styles from './LeafletMap.module.scss';
import { EditControl } from 'react-leaflet-draw';
import update from 'immutability-helper';
import { jsPDF } from 'jspdf';
import Utilities, { MapUtilities, Status } from '../../../helpers/Utilities';
import App from '../../../App';
import AddressAutoComplete from './AddressAutoComplete';
import MapCacheLayer from './MapCacheLayer';

export interface ILeafletMapProps {
    currentItem: IAssignmentDTO;
    onDismiss(currentItem: IAssignmentDTO): void;
    onSaveClicked(mapData: IMapData, canEdit: boolean): void;
}

export interface ILeafletMapState {
    currentItem: IAssignmentDTO;
    mapWidth: number;
    backgroundLayers: ILayer[];
    backgroundLayer: string;
    layers: ILayer[];
    selectedLayers: string[];

    // Drawing elements to persist
    currentWorkingArea?: IWorkingArea;
    currentStack?: IStackMarker;
    currentTextArea?: ITextArea;
    showWorkingAreaDialog: boolean;
    showStackInfoDialog: boolean;
    showDocumentationDialog: boolean;
    showTextAreaDialog: boolean;

    currentZoomLevel: number;
    currentPosition: LatLngTuple;
    mapData: IMapData;

    // Drawing
    activePolylineColor: string;
    activeMarkerIcon: L.Icon;
    isDrawing: boolean;
    currentDrawingObjectType: string;
    addedGeometryCount: number;    

    canEdit: boolean;
}

enum DrawingObjectType {
  None = "None",
  WorkingArea = "WorkingArea",
  RoadAllowed = "RoadAllowed",
  RoadProhibited = "RoadProhibited",
  Stack = "Stack",
  ContainerSpot = "ContainerSpot",
  FixedTaskPosition = "FixedTaskPosition",
  Polyline = "Polyline",
  TextArea = "TextArea"
};

export default class LeafletMap extends React.Component<ILeafletMapProps, ILeafletMapState> 
{
    private minZoomLevelForLayers = 8;
    private minZoomLevelForDrawingObjects = 7;
    private lineOrBorderWeight = 9;
    private mapRef : any;
    private groupRef : any;
    private editControl : any;

    constructor(props: ILeafletMapProps) 
    {
        super(props);
                
        MapUtilities.ConfigureLeafLet();  
        window.addEventListener('resize', this.handleResize.bind(this));        

        let mapData = props.currentItem.mapData ? JSON.parse(props.currentItem.mapData) as IMapData : { 
          workingAreas: [] as IWorkingArea[],
          stacks: [] as IStackMarker[],
          containerSpots: [] as IContainerSpot[],
          roads: [] as IRoad[],
          textAreas: [] as ITextArea[],
          defaultPosition: App.CurrentUserRegionDefaultPosition.defaultPosition,
          recentZoomLevel: 5
        } as IMapData;

        this.state = {
            currentItem: props.currentItem,
            mapWidth: this.getMapWidth(),
            backgroundLayers: [              
              { type:"wmts",name: "Luftfoto", attribution: "Orto", transparent: true, url: "https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/wmts?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A&layer=orto_foraar_wmts&style=default&tilematrixset=KortforsyningTilingDK&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/jpeg&TileMatrix={z}&TileCol={x}&TileRow={y}"} as ILayer,              
              { type:"wms", name: "Topo DTK25", attribution: "Topo DTK25", transparent: "TRUE", layers:"dtk25_2021", version: "1.3.0", url: "https://api.dataforsyningen.dk/dtk_25_DAF?token=ea2729ee97000593afc48e6feb11dfaf"} as ILayer,              
              { type:"wms", name: "Skærmkort", version: "1.1.1",transparent: "FALSE", layers: "dtk_skaermkort", url: "https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort/1.0.0/WMS?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A" } as ILayer
            ] as ILayer[],
            backgroundLayer: "Skærmkort",
            layers: [              
              //{ name: "Beskyttet natur §3", layers: "BES_NATURTYPER,bes_vandloeb", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_beskyttetnatur.png"} as ILayer,
              { name: "Beskyttet natur §3", layers: "dai:bes_naturtyper,dai:bes_vandloeb", url: "https://arealeditering-dist-geo.miljoeportal.dk/geoserver/wms", enabled: true, signature: "Signatur_beskyttetnatur.png"} as ILayer,
              //{ name: "Fredninger", layers: "fredede_omr", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_fredninger.png"} as ILayer,
              { name: "Fredninger", layers: "dai:fredede_omr", url: "https://arealeditering-dist-geo.miljoeportal.dk/geoserver/wms", enabled: true, signature: "Signatur_fredninger.png"} as ILayer,
              { name: "Fortidsminder", layers: "v_lokalitet_all", url: "https://www.kulturarv.dk/wms", enabled: true, signature: "Signatur_Fortidsminder.png", styles: "v_lokalitet_protected"} as ILayer,
              { name: "Beskyttelseslinjer", layers: "v_reg_lokalitet_beskyttelse_areal", url: "https://www.kulturarv.dk/wms", enabled: true, signature: "Signatur_Beskyttelseslinjer.png"} as ILayer,
              //{ name: "Beskyttede sten- og jordiger", layers: "BES_STEN_JORDDIGER", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_beskyttedestenjorddiger.png"} as ILayer,
              { name: "Beskyttede sten- og jordiger", layers: "dai:bes_sten_jorddiger", url: "https://arealeditering-dist-geo.miljoeportal.dk/geoserver/wms", enabled: true, signature: "Signatur_beskyttedestenjorddiger.png"} as ILayer,
              { name: "Natura 2000 områder", layers: "theme-pg-natura_2000_omraader", url: "https://miljoegis.mim.dk/wms?servicename=miljoegis-natura2000_wms", enabled: true, signature: "Signatur_natura2000omraader.png"} as ILayer,
              { name: "HNV", layers: "theme-plangroen_hnvskov", url: "https://miljoegis.mim.dk/wms?servicename=miljoegis-plangroendk_wms", enabled: false, signature: "Signatur_hnvskov.png"} as ILayer,
              { name: "Fredskovsarealer", layers: "FredskovsLinje_Gaeldende", url: "https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A", enabled: true, signature: "Signatur_fredskov.png"} as ILayer,              
              { name: "Matrikel", layers:"MatrikelSkel_Gaeldende,Centroide_Gaeldende", url: "https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A", enabled: false} as ILayer,
              { name: "Vejnavne", layers:"Vejnavne_ortofoto", url: "https://api.dataforsyningen.dk/forvaltning2?token=ea2729ee97000593afc48e6feb11dfaf", enabled: false} as ILayer
            ],
            selectedLayers: [] as string[],       
            mapData: mapData,
            currentPosition: props.currentItem.mapData ? mapData.defaultPosition : App.CurrentUserRegionDefaultPosition.defaultPosition as LatLngTuple,
            currentZoomLevel: props.currentItem.mapData ? mapData.recentZoomLevel : 5,
            activePolylineColor: "#fff",
            activeMarkerIcon: MapUtilities.CreateFixedTaskPositionIcon(props.currentItem.mapData ? mapData.recentZoomLevel : 5),
            isDrawing: false,
            currentDrawingObjectType: DrawingObjectType.None,
            addedGeometryCount: 0,
            showWorkingAreaDialog: false,
            showStackInfoDialog: false,
            showDocumentationDialog: false,
            showTextAreaDialog: false,
            canEdit: (props.currentItem.status != Status.Approved && props.currentItem.status != Status.Rejected) && (props.currentItem.status != Status.WaitingForApproval || App.IsUserInRole("DL"))
        };
    }

    public componentDidUpdate(prevProps: ILeafletMapProps, prevState: ILeafletMapState): void 
    {
      if(this.mapRef)
      {
        this.mapRef.leafletElement.off("dblclick", () => { this.completePolygon(); })
      }      
    }
    
    public render(): React.ReactElement<ILeafletMapProps> { 
      const CommandBar_Map: ICommandBarItemProps[] = [      
        {
          key: 'drawMap',
          text: 'Tegn område',
          iconProps: { iconName: 'WhiteBoardApp32' },    
          disabled: !this.state.canEdit,      
          subMenuProps: {
            items: [
            {
              key: 'add_workingArea',
              name: `Arbejdsområde (${this.state.mapData.workingAreas.filter(d => d.type == WorkingAreaType.WorkingArea).length})`,
              onClick: this.addWorkingArea.bind(this)
            },
            {
              key: 'add_line',
              name: `Linje (${this.state.mapData.workingAreas.filter(d => d.type == WorkingAreaType.Polyline).length})`,
              onClick: this.addPolyline.bind(this)
            }
            ]
          }
        },
        {
          key: 'add_fixedTaskPosition',
          text: 'Tilføj opgave GPS-punkt',
          iconProps: { iconName: 'POI' },
          onClick: this.drawFixedTaskPosition.bind(this),
          disabled: !this.state.canEdit || this.state.mapData.fixedTaskPosition != undefined
        },
        {
          key: 'add_stack',
          name: `Tilføj stak (${this.state.mapData.stacks.length})`,       
          iconProps: { iconName: 'MapLayers' },
          onClick: this.addStackToMap.bind(this),
          disabled: !this.state.canEdit
        },
        {
          key: 'add_containerSpot',
          name: `Tilføj containerplads (${this.state.mapData.containerSpots.length})`,
          iconProps: { iconName: 'RecycleBin' },
          onClick: this.addContainerSpot.bind(this),
          disabled: !this.state.canEdit
        },
        {
          key: 'add_road',
          text: 'Tilføj vej',
          iconProps: { iconName: 'Car' },
          disabled: !this.state.canEdit,
          subMenuProps: {
            items: [{
              key: 'add_roadAllowed',
              name: `Lastbil vej tilladt (${this.state.mapData.roads.filter(d => d.type == RoadType.Allowed).length})`,
              onClick: this.drawRoadAllowed.bind(this)
            },
            {
              key: 'add_roadProhibited',
              name: `Lastbil vej forbudt (${this.state.mapData.roads.filter(d => d.type == RoadType.Prohibited).length})`,
              onClick: this.drawButton_RoadProhibited.bind(this)
            }
            ]
          }
        },  
        {
          key: 'add_text',
          name: `Tilføj tekst (${this.state.mapData.textAreas.length})`,
          iconProps: { iconName: 'InsertTextBox' },
          onClick: this.addTextArea.bind(this),
          disabled: !this.state.canEdit
        }        
      ];

      const CommandBar_Map_FarItems: ICommandBarItemProps[] = [      
        {
          key: 'saveOrClose',
          text: this.state.canEdit ? "Gem kort" : "Luk kortet",
          iconProps: { iconName: this.state.canEdit ? 'Save' : 'ChromeClose' },
          className: this.state.canEdit ? styles.buttonSaveAndClose : "",
          onClick: () => {
            this.saveAll();

            //If we have a fixed task position, use this as the default position when opening the map in the future
            if(this.state.mapData.fixedTaskPosition)
            {
              this.state.mapData.defaultPosition = [this.state.mapData.fixedTaskPosition.position.lat, this.state.mapData.fixedTaskPosition.position.lng] as LatLngTuple;
            }        

            let mapData = this.state.mapData;
            mapData.recentZoomLevel = this.state.currentZoomLevel;
            mapData.stackSum = this.state.mapData.stacks?.reduce((n, {size}) => n + size, 0);              
            
            this.props.onSaveClicked(mapData, this.state.canEdit);
          }        
        }        
      ];

      if(this.state.canEdit)
      {
        CommandBar_Map_FarItems.splice(0, 0, {
          key: 'closeWithoutSaving',
          text: 'Luk uden at gemme',
          iconProps: { iconName: 'ChromeClose' },
          onClick: () => { this.props.onDismiss(this.state.currentItem) }
        });
      }

      return (<Dialog
          hidden={false}
          modalProps={{isBlocking: true }}
          minWidth={this.state.mapWidth}                                 
          title="Tegn kort">            
            <div>
              <AddressAutoComplete onAddressSelected={(x: number, y: number) => {
                this.setState({currentPosition: [y, x], currentZoomLevel: 11});
               }} />
            </div>
          <div className={styles.leafletMap}>
            <div className={styles.leftMenu}>                  
              {this.renderLeftMenu()}             
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.mapContainer}>
              <CommandBar className={styles.commandBarMap} items={CommandBar_Map} styles={{root: {paddingLeft:"0px", height:"38px"}}} farItems={CommandBar_Map_FarItems} />
              <Map onzoomend={this.onZoomEnd.bind(this)} zoomControl={true} ref={(ref : any) => { this.mapRef = ref; this.preRenderLeafletMap(); }} minZoom={3}  maxZoom={13} bounceAtZoomLimits={true} doubleClickZoom={false} crs={MapUtilities.CRS} className={styles.map} center={this.state.currentPosition} zoom={this.state.currentZoomLevel} scrollWheelZoom={true}>
                <ScaleControl imperial={false} />
                <LayersControl collapsed={false} position="topright">
                  {this.state.backgroundLayers.map((layer : ILayer, idx) =>
                      <LayersControl.BaseLayer key={`layer-${idx}`} checked={this.state.backgroundLayer === layer.name} name={layer.name}>
                        { layer.type == "wmts" &&
                            <TileLayer
                              minZoom={3}                              
                              maxZoom={13}
                              crossOrigin={true}                              
                              attribution={layer.attribution} 
                              url={layer.url} />
                        }
                        { layer.type == "wms" &&
                          <WMSTileLayer
                            layers={layer.layers}
                            minZoom={3}
                            maxZoom={13}                            
                            version={layer.version}
                            transparent={layer.transparent}
                            styles={"default"}
                            format={'image/png'}
                            url={layer.url} />
                        }
                        </LayersControl.BaseLayer>
                      )}     
                      {this.state.layers.map((layer : ILayer, idx) => 
                      <LayersControl.Overlay key={`overlay-${idx}`} name={layer.name} checked={layer.enabled}>                       
                          <WMSTileLayer
                            layers={layer.layers}
                            minZoom={this.minZoomLevelForLayers}
                            transparent={ (layer.name == "Matrikel" || layer.name == "Fredskovsarealer") ? "TRUE" as any : true}   //Some WMS api requires TRUE value in uppercase                           
                            format={'image/png'}
                            styles={layer.styles ? layer.styles : ""}
                            url={layer.url} />
                      </LayersControl.Overlay>
                      )}      
                    </LayersControl>
                <FeatureGroup ref={(ref : any) => { this.groupRef = ref; if(this.props.currentItem.mapData && !this.state.mapData.fixedTaskPosition) this.zoomToBounds(); }} key={`"FeatureGroup_${this.state.addedGeometryCount}`}>
                    <EditControl
                      ref={(ref : any) => { this.editControl = ref; }}
                      position="topleft"
                      draw={{
                        circle:false,
                        circlemarker: false,
                        rectangle: false,                            
                        polyline: {
                          shapeOptions: {
                            color:this.state.activePolylineColor,
                            opacity: 0.8
                          }
                        },
                        polygon: true,
                        marker: {
                          icon: this.state.activeMarkerIcon
                        },                                                                         
                      }}                      
                      onCreated={this._onCreated.bind(this)}        
                      onEdited={this._onEdited.bind(this)}
                    />                    
                    {this.state.mapData.fixedTaskPosition && this.renderFixedTaskPosition()}
                    {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.workingAreas?.map((workingarea : IWorkingArea) => this.renderWorkingArea(workingarea))} 
                    {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.roads?.map((road : IRoad) => this.renderRoad(road))}
                    {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.stacks?.map((stack : IStackMarker) => this.renderStack(stack))} 
                    {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.containerSpots?.map((marker : IContainerSpot) => this.renderContainerSpot(marker))}                     
                    {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.textAreas?.map((textArea : ITextArea) => this.renderTextArea(textArea))}             
                  </FeatureGroup>
                  <MapCacheLayer />
              </Map>                
            </div>
            {this.state.showWorkingAreaDialog && this.state.currentWorkingArea &&
              <WorkingAreaDialog currentWorkingArea={this.state.currentWorkingArea} onSaveClicked={(identifier: string, name: string, color: string) => {
                let workingAreas = [...this.state.mapData.workingAreas];
                let index = workingAreas.findIndex(e => e.identifier === identifier);
                workingAreas[index].name = name;
                workingAreas[index].color = color;

                this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, showWorkingAreaDialog: false, currentWorkingArea: undefined});
              }} />
            }
            {this.state.showStackInfoDialog && this.state.currentStack &&
              <StackDialog currentStack={this.state.currentStack} onSaveClicked={(identifier: string, no: string, size: number, treeSpecices: string, tooltipPosition: Direction) => {
                let stacks = [...this.state.mapData.stacks];
                let index = stacks.findIndex(e => e.identifier === identifier);
                stacks[index].no = no;
                stacks[index].size = size;
                stacks[index].treeSpecices = treeSpecices;
                stacks[index].tooltipPosition = tooltipPosition;

                this.setState({mapData: {...this.state.mapData, stacks: stacks}, showStackInfoDialog: false, currentStack: undefined, addedGeometryCount: this.state.addedGeometryCount+1});
              }} />
            }
            {this.state.showTextAreaDialog && this.state.currentTextArea &&
              <TextAreaDialog currentTextArea={this.state.currentTextArea} onSaveClicked={(identifier: string, name: string, color: string) => {
                let textAreas = [...this.state.mapData.textAreas];
                let index = textAreas.findIndex(e => e.identifier === identifier);
                textAreas[index].name = name;
                textAreas[index].color = color;

                this.setState({mapData: {...this.state.mapData, textAreas: textAreas}, showTextAreaDialog: false, currentTextArea: undefined});
              }} />
            }
          </div>
      </Dialog>);
    }

    private renderLeftMenu() : JSX.Element
    {
      return <React.Fragment>
        <div className={styles.leftMenuHeading}>Kortlag</div>
        <List items={this.state.layers} onRenderCell={this.onRenderLayerListItem.bind(this)} />
        <div className={styles.leftMenuDivider}></div>
        <div className={styles.leftMenuHeading}>Baggrundskort</div>
        <List items={this.state.backgroundLayers} onRenderCell={this.onRenderBackgroundLayerListItem.bind(this)} />
        {/*<div className={styles.leftMenuDivider}></div>
        <div className={styles.leftMenuHeading}>Tegneredskaber</div>                  
        <Stack tokens={{childrenGap: 5}}>
          <PrimaryButton disabled={!this.state.canEdit} text={`Tilføj arbejdsområde (${this.state.mapData.workingAreas.filter(d => d.type == WorkingAreaType.WorkingArea).length})`} onClick={this.addWorkingArea.bind(this)} />
          <PrimaryButton disabled={!this.state.canEdit} text={`Tilføj linje (${this.state.mapData.workingAreas.filter(d => d.type == WorkingAreaType.Polyline).length})`} onClick={this.addPolyline.bind(this)} />
          <PrimaryButton disabled={!this.state.canEdit} text={`Tilføj tekst (${this.state.mapData.textAreas.length})`} onClick={this.addTextArea.bind(this)}/>                  
          <PrimaryButton disabled={!this.state.canEdit} className={styles.drawButton_Stack} text={`Tilføj stak (${this.state.mapData.stacks.length})`} onClick={this.addStackToMap.bind(this)} />
          <PrimaryButton disabled={!this.state.canEdit} className={styles.drawButton_ContainerSpot} text={`Tilføj containerplads (${this.state.mapData.containerSpots.length})`} onClick={this.addContainerSpot.bind(this)} />
          <PrimaryButton disabled={!this.state.canEdit} className={styles.drawButton_RoadAllowed} text="Lastbil vej tilladt" onClick={this.drawRoadAllowed.bind(this)} />
          <PrimaryButton disabled={!this.state.canEdit} className={styles.drawButton_RoadProhibited} text="Lastbil vej forbudt" onClick={this.drawButton_RoadProhibited.bind(this)}/>
          <PrimaryButton disabled={!this.state.canEdit || this.state.mapData.fixedTaskPosition != undefined} className={styles.drawButton_FixedTaskPosition} title={this.state.mapData.fixedTaskPosition != undefined ? "Du kan kun angive ét opgave GPS-punkt" : ""} text="Angiv opgave GPS-punkt" onClick={this.drawFixedTaskPosition.bind(this)}/>                                
        </Stack>*/}
      </React.Fragment>
    }

    private renderWorkingArea(workingarea : IWorkingArea) : JSX.Element
    {
      if(workingarea.type == WorkingAreaType.WorkingArea)
      {
        return <Polygon editable={workingarea.isEditable} weight={this.lineOrBorderWeight} attribution={workingarea.identifier} key={workingarea.identifier} positions={workingarea.coordinates} opacity={0.7} fillColor={workingarea.color} color={workingarea.color == "transparent" ? "red" : workingarea.color} >
        <Popup>      
          <div style={{textAlign:"center"}}><b>Arbejdsområde: {workingarea.name}</b></div>
          {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton className={styles.popupButton} text={"Rediger"} onClick={() => { this.setState({showWorkingAreaDialog: true, currentWorkingArea: workingarea}); } } />    
              <PrimaryButton className={styles.popupButton} text={workingarea.isEditable ? "Gem ændringer" : "Ret område"} onClick={() => {    
                let workingAreas = [...this.state.mapData.workingAreas];                           
                let index = workingAreas.findIndex(d => d.identifier == workingarea.identifier);
                workingAreas[index].isEditable = !workingAreas[index].isEditable;
                
                if(workingAreas[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.WorkingArea});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let workingAreas = this.state.mapData.workingAreas.filter(d => d !== workingarea);
                this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } />
          </React.Fragment>
          }
        </Popup>
      </Polygon>
      } else
      {
        return <Polyline editable={workingarea.isEditable} weight={this.lineOrBorderWeight} attribution={workingarea.identifier} key={workingarea.identifier} positions={workingarea.coordinates} opacity={0.7} fillColor={workingarea.color} color={workingarea.color == "transparent" ? "red" : workingarea.color} >
          <Popup>
          <div style={{textAlign:"center"}}><b>Linje: {workingarea.name}</b></div>
          {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton className={styles.popupButton} text={"Rediger"} onClick={() => { this.setState({showWorkingAreaDialog: true, currentWorkingArea: workingarea}); } } />    
              <PrimaryButton className={styles.popupButton} text={workingarea.isEditable ? "Gem ændringer" : "Ret linje"} onClick={() => {    
                let workingAreas = [...this.state.mapData.workingAreas];                           
                let index = workingAreas.findIndex(d => d.identifier == workingarea.identifier);
                workingAreas[index].isEditable = !workingAreas[index].isEditable;
                
                if(workingAreas[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.WorkingArea});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let workingAreas = this.state.mapData.workingAreas.filter(d => d !== workingarea);
                this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } /> 
            </React.Fragment>
          }
          </Popup>
        </Polyline>
      }
    }

    private renderRoad(road: IRoad) : JSX.Element
    {
      return <Polyline editable={road.isEditable} weight={this.lineOrBorderWeight} attribution={road.identifier} key={road.identifier} positions={road.coordinates} opacity={0.7} color={road.type == RoadType.Allowed ? MapDrawingColors.RoadAllowed : MapDrawingColors.RoadProhibited} >
        <Popup>
          <div style={{textAlign:"center"}}><b>{road.type == RoadType.Allowed ? "Lastbil vej tilladt" : "Lastvil vej forbudt"}</b></div>
          {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton text={road.isEditable ? "Gem ændringer" : "Ret vej"} onClick={() => {    
                let roads = [...this.state.mapData.roads];                           
                let index = roads.findIndex(d => d.identifier == road.identifier);
                roads[index].isEditable = !roads[index].isEditable;
                
                if(roads[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, roads: roads}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: road.type == RoadType.Allowed ? DrawingObjectType.RoadAllowed : DrawingObjectType.RoadProhibited});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let roads = this.state.mapData.roads.filter(d => d !== road);
                this.setState({mapData: {...this.state.mapData, roads: roads}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } />
            </React.Fragment>
          }
        </Popup>
      </Polyline>
    }

    private renderFixedTaskPosition() : JSX.Element
    {
      if(!this.state.mapData.fixedTaskPosition)
      {
        return <React.Fragment />
      }

      return <Marker ondragend={(ev) => { 
        let position = ev.target.getLatLng();

        let taskPosition = this.state.mapData.fixedTaskPosition;

        if(taskPosition != undefined)
        {
          taskPosition.position = position as LatLng;  
          this.setState({mapData: {...this.state.mapData, fixedTaskPosition: taskPosition }, addedGeometryCount: this.state.addedGeometryCount+1});                          
        }
        
        }} draggable={this.state.mapData.fixedTaskPosition.isEditable} icon={MapUtilities.CreateFixedTaskPositionIcon(this.state.currentZoomLevel)} attribution={`fixedTaskPosition`} key={`fixedTaskPosition`} position={this.state.mapData.fixedTaskPosition.position}>      
      <Popup>
        <span>
          Opgave GPS: {this.state.mapData.fixedTaskPosition.position.lat},{this.state.mapData.fixedTaskPosition.position.lng}<br/>
          <Link target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${this.state.mapData.fixedTaskPosition.position.lat},${this.state.mapData.fixedTaskPosition.position.lng}`}>Åbn i Google Maps</Link><br/>
          {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton text={this.state.mapData.fixedTaskPosition.isEditable ? "Afslut redigering" : "Rediger"} onClick={() => {    
              let fixedTaskPosition = this.state.mapData.fixedTaskPosition;  

              if(fixedTaskPosition != undefined)
              {
                fixedTaskPosition.isEditable = !fixedTaskPosition?.isEditable;
                      
                if(fixedTaskPosition.isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, fixedTaskPosition: fixedTaskPosition}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.FixedTaskPosition});
                }
              }
              
            } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
              
              this.setState({mapData: {...this.state.mapData, fixedTaskPosition: undefined}, addedGeometryCount: this.state.addedGeometryCount+1});
            } } />
          </React.Fragment>
        }
        </span>
      </Popup>
    </Marker>
    }

    private renderStack(stack: IStackMarker) : JSX.Element
    {
      let icon : L.Icon<L.IconOptions> = MapUtilities.CreateStackIcon(this.state.currentZoomLevel, stack.tooltipPosition);

      return <Marker ondragend={(ev) => { 
        let position = ev.target.getLatLng();

        let stacks = [...this.state.mapData.stacks];
        let index = stacks.findIndex(e => e.identifier === stack.identifier);
        stacks[index].position = position as LatLng;

        this.setState({mapData: {...this.state.mapData, stacks: stacks}, addedGeometryCount: this.state.addedGeometryCount+1});                          
        }} 
        draggable={stack.isEditable} icon={icon} attribution={stack.identifier} key={stack.identifier} position={stack.position}>
          <Tooltip permanent={true} direction={stack.tooltipPosition ?? "auto"} opacity={0.5}>
          <div>
            <div>Nr: {stack.no}</div>
            <div>{stack.size} rm</div>
            <div>{stack.treeSpecices}</div>
          </div>
        </Tooltip>      
        {this.state.canEdit && 
          <Popup>    
            <React.Fragment>
              <PrimaryButton className={styles.popupButton} text={"Rediger"} onClick={() => { this.setState({showStackInfoDialog: true, currentStack: stack}); } } />    
              <PrimaryButton className={styles.popupButton} text={stack.isEditable ? "Gem ændringer" : "Flyt stak"} onClick={() => {    
                let stacks = [...this.state.mapData.stacks];                           
                let index = stacks.findIndex(d => d.identifier == stack.identifier);
                stacks[index].isEditable = !stacks[index].isEditable;
                
                if(stacks[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, stacks: stacks}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.Stack});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let stacks = this.state.mapData.stacks.filter(d => d !== stack);
                this.setState({mapData: {...this.state.mapData, stacks: stacks}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } />
              </React.Fragment>
          </Popup>
        }
      </Marker>
    }

    private renderContainerSpot(containerSpot: IContainerSpot) : JSX.Element
    {
      return <Marker ondragend={(ev) => { 
        let position = ev.target.getLatLng();

        let containerSpots = [...this.state.mapData.containerSpots];
        let index = containerSpots.findIndex(e => e.identifier === containerSpot.identifier);
        containerSpots[index].position = position as LatLng;

        this.setState({mapData: {...this.state.mapData, containerSpots: containerSpots}, addedGeometryCount: this.state.addedGeometryCount+1});                          
        }} draggable={containerSpot.isEditable} icon={MapUtilities.CreateContainerspotIcon(this.state.currentZoomLevel)} attribution={containerSpot.identifier} key={containerSpot.identifier} position={containerSpot.position}>
        <Popup>
          <div style={{textAlign:"center"}}><b>Containerplads</b></div>
          {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton text={containerSpot.isEditable ? "Gem ændringer" : "Flyt containerplads"} onClick={() => {    
                let containerSpots = [...this.state.mapData.containerSpots];                           
                let index = containerSpots.findIndex(d => d.identifier == containerSpot.identifier);
                containerSpots[index].isEditable = !containerSpots[index].isEditable;
                
                if(containerSpots[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, containerSpots: containerSpots}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.ContainerSpot});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let containerSpots = this.state.mapData.containerSpots.filter(d => d !== containerSpot);
                this.setState({mapData: {...this.state.mapData, containerSpots: containerSpots}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } />
              </React.Fragment>
          }
        </Popup>
      </Marker>
    }

    private renderTextArea(textArea: ITextArea) : JSX.Element
    {
      if(!textArea.name)
      {
        return <React.Fragment />;
      }

      return <Marker ondragend={(ev) => { 
        let position = ev.target.getLatLng();

        let textAreas = [...this.state.mapData.textAreas];
        let index = textAreas.findIndex(e => e.identifier === textArea.identifier);
        textAreas[index].position = position as LatLng;

        this.setState({mapData: {...this.state.mapData, textAreas: textAreas}, addedGeometryCount: this.state.addedGeometryCount+1});                          
        }} draggable={textArea.isEditable} icon={MapUtilities.CreateTextIcon(`<div style="display:inline-block; color:#fff; padding:5px; white-space:nowrap; opacity: 0.7; background-color:${textArea.color}">${textArea.name}</div>`)} attribution={textArea.identifier} key={textArea.identifier} position={textArea.position}>
        <Popup>
        {this.state.canEdit && 
            <React.Fragment>
              <PrimaryButton className={styles.popupButton} text={"Rediger"} onClick={() => { this.setState({showTextAreaDialog: true, currentTextArea: textArea}); } } />
              <PrimaryButton className={styles.popupButton} text={textArea.isEditable ? "Gem ændringer" : "Flyt tekst"} onClick={() => {    
                let textAreas = [...this.state.mapData.textAreas];
                let index = textAreas.findIndex(d => d.identifier == textArea.identifier);
                textAreas[index].isEditable = !textAreas[index].isEditable;
                
                if(textAreas[index].isEditable === false)
                {
                  this.drawingStop();
                } else
                {
                  // Set active drawing type and force map drawing layer refresh
                  this.setState({mapData: {...this.state.mapData, textAreas: textAreas}, addedGeometryCount: this.state.addedGeometryCount+1, currentDrawingObjectType: DrawingObjectType.TextArea});
                }
              } } />
              <DefaultButton className={styles.mapPopupDeleteButton} text="Slet" onClick={() => {                               
                let textAreas = this.state.mapData.textAreas.filter(d => d !== textArea);
                this.setState({mapData: {...this.state.mapData, textAreas: textAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
              } } /> 
              </React.Fragment>
            }       
        </Popup>
      </Marker>
    }

    private preRenderLeafletMap() 
    {
      if(this.mapRef != null)
      {
        if(this.state.isDrawing && this.state.currentDrawingObjectType == DrawingObjectType.WorkingArea)
        {
          this.mapRef.leafletElement.on("dblclick", () => { this.completePolygon(); })
        }
      }      
    }

    private zoomToBounds()
    {
      if(this.mapRef != null && this.groupRef != null)
      {
        //Zoom to fit outer bounds of elements on map
        const map = this.mapRef.leafletElement;
        const group = this.groupRef.leafletElement;
        const bounds = group.getBounds();

        if(bounds.isValid())
        {
          map.fitBounds(bounds);
        }        
      }
    }

    private completePolygon()
    {
      if(this.editControl.leafletElement._toolbars.draw._modes.polygon.handler._markers)
      {
        if(this.editControl.leafletElement._toolbars.draw._modes.polygon.handler._markers.length >=3)
        {
          this.editControl.leafletElement._toolbars.draw._modes.polygon.handler.completeShape();
  
          this.setState({
            isDrawing:false
          });
        }  
      }          
    }

    private onZoomEnd()
    {
      if(this.mapRef && this.mapRef.leafletElement)
      {
        var currentZoom = this.mapRef.leafletElement.getZoom(); 
        this.setState({currentZoomLevel: currentZoom});
      }
    }

    private onRenderLayerListItem(item?: ILayer, index?: number, isScrolling?: boolean): JSX.Element {
      const isActive = item?.enabled;
      return (
        <div className={styles.leftMenuItem} >
          <div className={styles.leftMenuItemRow} data-is-focusable={true} title={item?.name + ""} onClick={() => { 
            let newState = update(this.state, {
              layers: {
                [index as number]: {
                  enabled: {
                    $set: !item?.enabled
                  } 
                }
              }
            });

            this.setState(newState);
            }}>          
            <Icon iconName={isActive ? 'RedEye' : 'Hide'} />
            <span>{item?.name + ''}</span>
          </div>
          {item?.signature && 
            <div className={styles.leftMenuItemMore} onClick={() => { 
              let newState = update(this.state, {
                layers: {
                  [index as number]: {
                    showSignature: {
                      $set: !item?.showSignature
                    } 
                  }
                }
              });

              this.setState(newState);
             }} >              
              <Icon id={`hdmore_icon_${index}`} iconName={'More'} />
              {item?.showSignature && 
                <Callout
                  target={`#hdmore_icon_${index}`}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <img src={`/images/${item.signature}`} />
                </Callout>
              }
              </div>
          }
        </div>
      );
    }

    private onRenderBackgroundLayerListItem(item?: ILayer, index?: number, isScrolling?: boolean): JSX.Element {
      const isActive = this.state.backgroundLayer === item?.name;
      return (
        <div className={styles.leftMenuItem} >
          <div className={styles.leftMenuItemRow} data-is-focusable={true} title={item?.name + ""} onClick={() => { 
            this.setState({backgroundLayer: item?.name + "", backgroundLayers: [...this.state.backgroundLayers]});
            }}>          
            <Icon iconName={isActive ? 'RedEye' : 'Hide'} />
            <span>{item?.name + ''}</span>          
          </div>
        </div>
      );
    }

    private _onCreated(e:any) {      
      let data = e.layer.toGeoJSON();

      switch(this.state.currentDrawingObjectType)
      {
        case DrawingObjectType.WorkingArea:  
        {
          let workingAreas = [...this.state.mapData.workingAreas];
          let newWorkingArea = { type: WorkingAreaType.WorkingArea, identifier: `polygon-workingarea-${Utilities.NewGuid()}`, isEditable: false, coordinates: data.geometry.coordinates[0].map((c) => { return { lat: c[1], lng: c[0] } })} as IWorkingArea;
          workingAreas.push(newWorkingArea);

          this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1, currentWorkingArea: newWorkingArea, showWorkingAreaDialog: true});
          break;
        }          
        case DrawingObjectType.Polyline:  
        {
          let workingAreas = [...this.state.mapData.workingAreas];
          let newWorkingArea = { type: WorkingAreaType.Polyline, identifier: `polyline-workingarea-${Utilities.NewGuid()}`, isEditable: false, coordinates: data.geometry.coordinates.map((c) => { return { lat: c[1], lng: c[0] } })} as IWorkingArea;
          workingAreas.push(newWorkingArea);

          this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1, currentWorkingArea: newWorkingArea, showWorkingAreaDialog: true});
          break;
        }
        case DrawingObjectType.ContainerSpot:
          let containerSpots = [...this.state.mapData.containerSpots];
          containerSpots.push({ identifier: `marker-containerspot-${Utilities.NewGuid()}`, isEditable: false, position: { lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0] }} as IContainerSpot);

          this.setState({mapData : {...this.state.mapData, containerSpots: containerSpots}, addedGeometryCount: this.state.addedGeometryCount+1});
          break;
        case DrawingObjectType.Stack:
          let stacks = [...this.state.mapData.stacks];
          let newStack = {identifier: `marker-stack-${Utilities.NewGuid()}`, isEditable: false, position: {lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0]} as LatLng, size: 0} as IStackMarker;
          stacks.push(newStack);

          this.setState({mapData: {...this.state.mapData, stacks: stacks}, addedGeometryCount: this.state.addedGeometryCount+1, currentStack: newStack, showStackInfoDialog: true});
          break;
        case DrawingObjectType.RoadAllowed:
          let roadsAllowed = [...this.state.mapData.roads];
          roadsAllowed.push({identifier: `polyline-road-allowed-${Utilities.NewGuid()}`, isEditable: false, type: RoadType.Allowed, coordinates: data.geometry.coordinates.map((c) => { return { lat: c[1], lng: c[0] } }) });

          this.setState({mapData: {...this.state.mapData, roads: roadsAllowed}, addedGeometryCount: this.state.addedGeometryCount+1});
          break;
        case DrawingObjectType.RoadProhibited:
          let roadsProhibited = [...this.state.mapData.roads];
          roadsProhibited.push({identifier: `polyline-road-prohibited-${Utilities.NewGuid()}`, isEditable: false, type: RoadType.Prohibited, coordinates: data.geometry.coordinates.map((c) => { return { lat: c[1], lng: c[0] } }) });

          this.setState({mapData: {...this.state.mapData, roads: roadsProhibited}, addedGeometryCount: this.state.addedGeometryCount+1});
          break;
        case DrawingObjectType.FixedTaskPosition:          
          this.setState({mapData: {...this.state.mapData, fixedTaskPosition: { isEditable: false, position: { lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0]} as LatLng} }, addedGeometryCount: this.state.addedGeometryCount+1});
          break;
        case DrawingObjectType.TextArea:   
          let textareas = [...this.state.mapData.textAreas];
          let newTextArea = { identifier: `marker-textarea-${Utilities.NewGuid()}`, isEditable: false, position: { lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0] }} as ITextArea;
          textareas.push(newTextArea);

          this.setState({mapData : {...this.state.mapData, textAreas: textareas}, addedGeometryCount: this.state.addedGeometryCount+1, currentTextArea: newTextArea, showTextAreaDialog: true});
          break;
      }
    }

    private _onEdited(e:any)
    {
      e.layers.eachLayer(a => {
        let data = a.toGeoJSON();
        const identifier = a.options.attribution;

         if(identifier.indexOf("marker-stack") == 0)
        {
          let stacks = [...this.state.mapData.stacks];
          let index = stacks.findIndex(e => e.identifier === identifier);
          stacks[index].position = {lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0]} as LatLng;

          this.setState({mapData: {...this.state.mapData, stacks: stacks}, addedGeometryCount: this.state.addedGeometryCount+1});
        } else if(identifier.indexOf("marker-containerspot") == 0)
        {
          let containerSpots = [...this.state.mapData.containerSpots];
          let index = containerSpots.findIndex(e => e.identifier === identifier);
          containerSpots[index].position = {lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0]} as LatLng;

          this.setState({mapData: {...this.state.mapData, containerSpots: containerSpots}, addedGeometryCount: this.state.addedGeometryCount+1});
        } else if(identifier.indexOf("polygon-workingarea") == 0)
        {
          let workingAreas = [...this.state.mapData.workingAreas];
          let index = workingAreas.findIndex(e => e.identifier === identifier);
          
          let coordinates = [] as LatLng[];
          for (var i = 0; i < data.geometry.coordinates.length; i++) {
            for (var j = 0; j < data.geometry.coordinates[i].length; j++) {
              coordinates.push({lat: data.geometry.coordinates[i][j][1], lng: data.geometry.coordinates[i][j][0]} as LatLng);
            }
          }
          
          workingAreas[index].coordinates = coordinates;

          this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
        } else if(identifier.indexOf("polyline-road") == 0)
        {
          let roads = [...this.state.mapData.roads];
          let index = roads.findIndex(e => e.identifier === identifier);

          let coordinates = [] as LatLng[];
          for (var i = 0; i < data.geometry.coordinates.length; i++) {
              coordinates.push({lat: data.geometry.coordinates[i][1], lng: data.geometry.coordinates[i][0]} as LatLng);            
          }

          roads[index].coordinates = coordinates;

          this.setState({mapData: {...this.state.mapData, roads: roads}, addedGeometryCount: this.state.addedGeometryCount+1});
        } else if(identifier.indexOf("polyline-workingarea") == 0)
        {
          let workingAreas = [...this.state.mapData.workingAreas];
          let index = workingAreas.findIndex(e => e.identifier === identifier);

          let coordinates = [] as LatLng[];
          for (var i = 0; i < data.geometry.coordinates.length; i++) {
              coordinates.push({lat: data.geometry.coordinates[i][1], lng: data.geometry.coordinates[i][0]} as LatLng);            
          }

          workingAreas[index].coordinates = coordinates;

          this.setState({mapData: {...this.state.mapData, workingAreas: workingAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
        } else if(identifier.indexOf("marker-textarea") == 0)
        {
          let textAreas = [...this.state.mapData.textAreas];
          let index = textAreas.findIndex(e => e.identifier === identifier);

          textAreas[index].position = {lat: data.geometry.coordinates[1], lng: data.geometry.coordinates[0]} as LatLng;

          this.setState({mapData: {...this.state.mapData, textAreas: textAreas}, addedGeometryCount: this.state.addedGeometryCount+1});
        }
      });
    }

    private addWorkingArea()
    {
      this.setState({
        isDrawing: true,
        currentDrawingObjectType: DrawingObjectType.WorkingArea
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.polygon.handler.enable();
      }); 
    }

    private addPolyline()
    {
      this.setState({
        activePolylineColor: MapDrawingColors.Polyline,
        isDrawing: true,
        currentDrawingObjectType: DrawingObjectType.Polyline
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.enable();
      });      
    }

    private addStackToMap()
    {
      this.setState({
        currentDrawingObjectType: DrawingObjectType.Stack,
        activeMarkerIcon: MapUtilities.CreateStackIcon(this.state.currentZoomLevel)
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.marker.handler.enable();
      });       
    }

    private addContainerSpot()
    {
      this.setState({
        currentDrawingObjectType: DrawingObjectType.ContainerSpot,
        activeMarkerIcon: MapUtilities.CreateContainerspotIcon(this.state.currentZoomLevel),
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.marker.handler.enable();
      }); 
    }

    private drawRoadAllowed()
    {
      this.setState({
        activePolylineColor: MapDrawingColors.RoadAllowed,
        isDrawing: true,
        currentDrawingObjectType: DrawingObjectType.RoadAllowed
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.enable();
      });      
    }

    private drawButton_RoadProhibited()
    {
      this.setState({
        activePolylineColor: MapDrawingColors.RoadProhibited,
        isDrawing: true,
        currentDrawingObjectType: DrawingObjectType.RoadProhibited
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.enable();
      });      
    }

    private drawFixedTaskPosition()
    {
      this.setState({
        activeMarkerIcon: MapUtilities.CreateFixedTaskPositionIcon(this.state.currentZoomLevel),
        currentDrawingObjectType: DrawingObjectType.FixedTaskPosition
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.marker.handler.enable();
      }); 
    }

    private addTextArea()
    {
      this.setState({
        activeMarkerIcon: MapUtilities.none,
        currentDrawingObjectType: DrawingObjectType.TextArea
      }, () => {
        this.editControl.leafletElement._toolbars.draw._modes.marker.handler.enable();
      }); 
    }

    private drawingStop()
    {
      switch(this.state.currentDrawingObjectType)
      {
        case DrawingObjectType.WorkingArea:
          this.editControl.leafletElement._toolbars.draw._modes.polygon.handler.disable();
          this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.disable();
          break;
        case DrawingObjectType.RoadAllowed:
        case DrawingObjectType.RoadProhibited:        
          //this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.completeShape();
          this.editControl.leafletElement._toolbars.draw._modes.polyline.handler.disable();
          break;
        case DrawingObjectType.Stack:
          this.editControl.leafletElement._toolbars.edit._modes.edit.handler.enable();
          break;
      }

      this.editControl.leafletElement._toolbars.edit._modes.edit.handler.save(); 
      this.editControl.leafletElement._toolbars.edit._modes.edit.handler.disable();

      this.setState({isDrawing: false, currentDrawingObjectType: DrawingObjectType.None, addedGeometryCount: this.state.addedGeometryCount+1});
    }

    private saveAll()
    {
      this.drawingStop();

      let mapData = this.state.mapData;

      mapData.workingAreas.forEach( (item, i, self) => item.isEditable = false );  
      mapData.roads.forEach( (item, i, self) => item.isEditable = false ); 
      mapData.stacks.forEach( (item, i, self) => item.isEditable = false ); 
      mapData.containerSpots.forEach( (item, i, self) => item.isEditable = false ); 
      mapData.textAreas.forEach( (item, i, self) => item.isEditable = false );
      
      if(mapData.fixedTaskPosition)
      {
          mapData.fixedTaskPosition.isEditable = false;
      }

      // Persist geo json for drawn objects
      mapData.geoJson = [] as any[];

      this.mapRef.leafletElement.eachLayer((layer) => {
        if(!layer._url)
        {
          try {
            let geoJson = layer.toGeoJSON();

            if(geoJson.type == "Feature")
            {
              let props = { "type": this.translateAttribution(layer.options.attribution), "info": {} };

              if(props.type == "Stack")
              {
                let stack : IStackMarker = mapData.stacks.filter(d => d.position == layer.options.position)[0];
                if(stack)
                {
                  props.info = { "No": stack.no, "TreeSpecices": stack.treeSpecices, "Volume": stack.size };
                }                
              }

              if(props.type == "WorkingArea" || props.type == "Line")
              {
                let wa : IWorkingArea = mapData.workingAreas.filter(d => d.identifier == layer.options.attribution)[0];
                if(wa)
                {
                  props.info = { "StandInfo": wa.name };
                }
              }

              if(props.type == "Text")
              {
                let ta : ITextArea = mapData.textAreas.filter(d => d.identifier == layer.options.attribution)[0];
                if(ta)
                {
                  props.info = { "Value": ta.name };
                }
              }

              geoJson.properties = props;              

              mapData.geoJson.push(geoJson);
            }            
        } catch(e) {};
        }
      });
      
      this.setState({mapData: mapData});
    }

    private translateAttribution(attribution: string) : string
    {
      if(attribution == "fixedTaskPosition") { return "TaskGPS"; } 
      else if(attribution.indexOf("polygon-workingarea")> -1) { return "WorkingArea"; }
      else if(attribution.indexOf("polyline-workingarea")> -1) { return "WorkingArea"; } //Send Line as WorkingArea too
      else if(attribution.indexOf("polyline-road-allowed")> -1) { return "RoadAllowed"; }
      else if(attribution.indexOf("polyline-road-prohibited")> -1) { return "RoadProhibited"; }
      else if(attribution.indexOf("marker-stack")> -1) { return "Stack"; } 
      else if(attribution.indexOf("marker-containerspot")> -1) { return "ContainerSpot"; }       
      else if(attribution.indexOf("marker-textarea")> -1) { return "Text"; }       
      else { return "N/A"; }
    }

    private getMapWidth()
    {
      return window.innerWidth * 0.95;
    }

    private handleResize()
    {
      this.setState({mapWidth: this.getMapWidth()});
    }
}