import { CreateAuthHeader } from "../helpers/authentication-helper";
import Utilities from "../helpers/Utilities";
import { IAnnouncementDTO, IDeleteAnnouncementDTO } from "../models";

export interface IAnnouncementService
{
    GetAnnouncements() : Promise<IAnnouncementDTO[]>;
    CreateAnnouncement(model: IAnnouncementDTO) : Promise<boolean>;
    UpdateAnnouncement(model: IAnnouncementDTO) : Promise<boolean>;
    DeleteAnnouncement(model: IDeleteAnnouncementDTO) : Promise<boolean>;    
}

export class AnnouncementServiceProvider implements IAnnouncementService
{
    public async GetAnnouncements() : Promise<IAnnouncementDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch('announcements', requestOptions);
        if(!response.ok)
        {
        }

        let announcements = await response.json() as IAnnouncementDTO[];

        for(let a of announcements)
        {
            a.created = Utilities.ConvertFromUTCToLocalTime(a.created + "Z");
        }

        return announcements;
    }

    public async CreateAnnouncement(model: IAnnouncementDTO) : Promise<boolean>
    {   
        if(!model.text)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('announcements', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async UpdateAnnouncement(model: IAnnouncementDTO) : Promise<boolean>
    {
        if(!model.text)
        {
            return false;
        }

        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('announcements', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async DeleteAnnouncement(model: IDeleteAnnouncementDTO) : Promise<boolean>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('announcements', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }
}