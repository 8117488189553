import { CommandBar, ConstrainMode, DetailsListLayoutMode, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList, Selection, IObjectWithKey, IColumn, Pivot, PivotItem, SearchBox } from "@fluentui/react";
import * as React from "react";
import { IAnnouncementDTO, ICreateOrUpdateSupplierDTO, ICreateOrUpdateUserDTO } from "../../models";
import { AnnouncementServiceProvider, IAnnouncementService, Services } from "../../services";
import AnnouncementPanel from './AnnouncementPanel/AnnouncementPanel';
import App from "../../App";

interface IUserManagementProps
{
    
}

interface IUserManagementState
{
    isLoading: boolean;
    announcements: IAnnouncementDTO[];
    selectedAnnouncement?: IAnnouncementDTO;
    showAnnouncementPanel: boolean;
}

export default class AnnouncementManagement extends React.Component<IUserManagementProps, IUserManagementState> {
    private announcementService : IAnnouncementService;
    private _selection: Selection;

    private columns: IColumn[] = [
        {
          key: 'text',
          name: 'Tekst',
          fieldName: 'text',
          minWidth: 500,
          maxWidth: 500,
          isResizable: true
        },
        {
            key: 'created',
            name: 'Oprettet',
            fieldName: 'created',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: IAnnouncementDTO) => { 
                return item.created?.toLocaleDateString();
              },
        }
    ];

    constructor(props: IUserManagementProps) {
        super(props);

        this.announcementService = Services.AnnouncementService.Initialize(new AnnouncementServiceProvider());
    
        this._selection = new Selection({
            onSelectionChanged: () => {                 
                this.setState({ selectedAnnouncement: (this._selection.getSelection() as IObjectWithKey | any)[0] as IAnnouncementDTO});
              }
        });
      
        this.state = {
            isLoading: true,
            announcements: [] as IAnnouncementDTO[],
            showAnnouncementPanel: false
        };
        
        this.getData();
    }

    public render(): React.ReactElement<IUserManagementProps> {
        const CommandBar_Announcements: ICommandBarItemProps[] = [
            {
                key: 'newAnnouncement',
                text: 'Ny besked',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({selectedAnnouncement: undefined, showAnnouncementPanel: true }, () => { this._selection.setAllSelected(false); }) }      
            },
            {
                key: 'editUser',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({showAnnouncementPanel: true}) },
                disabled: !this.state.selectedAnnouncement
            }
        ];

        return (<div>
                    <h3>Beskeder</h3>
                    <CommandBar items={CommandBar_Announcements} styles={{root: {paddingLeft:"0px"}}} />
                                <ShimmeredDetailsList
                                    items={this.state.announcements}
                                    columns={this.columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    onItemInvoked={(item: IAnnouncementDTO) => { this.setState({showAnnouncementPanel: true}); }}
                                    constrainMode={ConstrainMode.unconstrained}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    enableShimmer={this.state.isLoading}
                                    setKey="set"
                                />   
                                {this.state.showAnnouncementPanel && <AnnouncementPanel service={this.announcementService} selectedAnnouncement={this.state.selectedAnnouncement} onChanged={(updatedItem: IAnnouncementDTO) => { this.setState({selectedAnnouncement: updatedItem}); this.getData(); }} onDismiss={() => { this.setState({showAnnouncementPanel: false}); } } />}                                       
                            </div>);
    }

    private async getData()
    {        
        this._selection.setAllSelected(false);

        let announcements = await this.announcementService.GetAnnouncements();

        this.setState(
        {
            announcements: announcements, 
            isLoading:false,
            showAnnouncementPanel: false
        });      
    }    
}