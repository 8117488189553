import React from 'react';
import { PrimaryButton, DefaultButton, Panel, PanelType, elementContainsAttribute, Stack, TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import styles from './UserPanel.module.scss';
import { ICreateOrUpdateUserDTO, IRoleDTO, ICreateOrUpdateSupplierDTO, IRegionDTO } from '../../../models';
import { IUserService, Services, UserServiceProvider } from '../../../services';
import App from '../../../App';

interface IUserPanelProps
{
    selectedUser? : ICreateOrUpdateUserDTO;
    selectedSupplier?: ICreateOrUpdateSupplierDTO;
    onDismiss(): void;
    onChanged(user: ICreateOrUpdateUserDTO, reason: string): void;
}

interface IUserPanelState
{
    currentItem : ICreateOrUpdateUserDTO;
    canSubmit: boolean;
    suppliers?: ICreateOrUpdateSupplierDTO[];
    regions?: IRegionDTO[];
}

export enum UserPanelCloseReason
{
    AddOrUpdate = "AddOrUpdate",
    Delete = "Delete",
}

export default class UserPanel extends React.Component<IUserPanelProps, IUserPanelState> {        
    private userService : IUserService;
    
    constructor(props: IUserPanelProps) {
        super(props);

        this.userService = Services.UserService.Initialize(new UserServiceProvider());
        
        this.state = {
            canSubmit: true,
            currentItem: props.selectedUser ? props.selectedUser : { supplier: this.props.selectedSupplier ? this.props.selectedSupplier : undefined, roles: [{name:"Supplier"}] as IRoleDTO[] } 
        };

        this.load();
    }

    public render(): React.ReactElement<IUserPanelProps> {
        let roles = [
            { key: 'Supplier', text: 'Leverandør' }            
        ];

        if(App.IsUserInRole("Admin"))
        {
            roles.push({ key: 'DL', text: 'Driftsleder' }, { key: 'Finance', text: 'Økonomimedarbejder' },{ key: 'Admin', text: 'Administrator' })
        };

        return (
            <Panel
              headerText={this.props.selectedUser ? "Rediger bruger" : "Opret ny bruger"}
              isOpen={true}  
              isBlocking={true}   
              className={styles.userPanel}                 
              isLightDismiss={false}              
              onRenderFooterContent={() => {
                  return (<div>
                    <PrimaryButton disabled={!this.state.canSubmit} style={{marginRight:"10px"}} 
                        onClick={() => {this.onAddOrUpdateAssignment()}}
                        >
                        {this.props.selectedUser ? "Gem" : "Opret"}
                    </PrimaryButton>
                    { this.props.selectedUser && 
                        <PrimaryButton 
                            className={styles.deleteButton} 
                            onClick={async () => { this.deleteUser(); } }
                            >
                            Slet bruger
                        </PrimaryButton> 
                    }
                    <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>
                </div>);                
                }
              }
              onDismiss={() => this.props.onDismiss()}              
              closeButtonAriaLabel="Luk brugerpanel"
              type={PanelType.medium}
            >                
                <Stack>
                    <Dropdown
                        required={true}
                        placeholder="Vælg fra listen"
                        //multiSelect={true}
                        multiSelect={false}
                        label="Roller"
                        //selectedKeys={this.state.currentItem.roles.map(d => d.name)}                        
                        selectedKey={this.state.currentItem.roles.map(d => d.name)}                        
                        options={roles}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.onRolesChanged(event, option) }}                        
                    />
                    { this.state.currentItem.roles.filter(d => d.name == "Supplier").length > 0 && 
                        <Dropdown
                            required={true}
                            placeholder="Vælg fra listen"
                            disabled={this.props.selectedSupplier != undefined}
                            label="Leverandør"
                            selectedKey={this.state.currentItem.supplier?.id}                        
                            options={this.state.suppliers ? this.state.suppliers.map((value : ICreateOrUpdateSupplierDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, supplier: this.state.suppliers?.filter(d => d.id == option?.key)[0] }}); }}                        
                        />
                    }
                    { this.state.currentItem.roles.filter(d => d.name == "DL").length > 0 && 
                        <Dropdown
                            required={true}
                            placeholder="Vælg fra listen"
                            label="Region"
                            selectedKey={this.state.currentItem.region?.id}                        
                            options={this.state.regions ? this.state.regions.map((value : IRegionDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, region: this.state.regions?.filter(d => d.id == option?.key)[0] }}); }}                        
                        />
                    }                    

                    <TextField 
                        required={true}                         
                        label="Navn" 
                        placeholder="Angiv navnet på brugeren" 
                        autoComplete="off_navn"
                        value={this.state.currentItem.name} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, name: newValue }}); }}
                    />
                    <TextField 
                        required={true} 
                        label="E-mail" 
                        autoComplete="off_email"
                        placeholder="Angiv e-mailen på brugeren" 
                        value={this.state.currentItem.email} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, email: newValue }}); }}
                    />
                    <TextField 
                        required={false}                         
                        label="Telefonnummer" 
                        placeholder="Angiv telefonnummeret på brugeren" 
                        autoComplete="off_telefonnummer"
                        value={this.state.currentItem.workPhone} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, workPhone: newValue }}); }}
                    />
                    <TextField 
                        required={false} 
                        label="Adgangskode"
                        placeholder={this.props.selectedUser ? "Overskriv den eksisterende adgangskode" : "Angiv den ønskede adgangskode" }
                        type="password" 
                        description={this.props.selectedUser ? "Udfyld kun dette felt, hvis du ønsker at overskrive brugerens aktuelle adgangskode" : ""}
                        autoComplete="new-password"
                        canRevealPassword 
                        revealPasswordAriaLabel="Vis adgangskode" 
                        value={this.state.currentItem.password} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, password: newValue }}); }}
                    />                        
                </Stack>
            </Panel>
        );
    }

    private async load()
    {
        let suppliers = await this.userService.GetSuppliers();

        let regions = [] as IRegionDTO[];

        if(App.IsUserInRole("Admin"))
        {
            regions = await this.userService.GetRegions();
        }

        this.setState({ suppliers:suppliers, regions: regions });
    }

    private onRolesChanged(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption)
    {
        if(item)
        {
            /*let roles = item.selected ? [...this.state.currentItem.roles, { name: item.key, displayName: item.text }] as IRoleDTO[] : this.state.currentItem.roles.filter(key => key.name !== item.key) as IRoleDTO[]

            this.setState({ currentItem: { ...this.state.currentItem, roles: roles }});*/
            this.setState({ currentItem: { ...this.state.currentItem, roles: [{ name: item.key, displayName: item.text }] as IRoleDTO[] }});
        }
    }

    private async onAddOrUpdateAssignment()
    {
        let result : boolean = false;
        if(this.props.selectedUser)
        {
            result = await this.userService.UpdateUser(this.state.currentItem);
        } else
        {
            result = await this.userService.CreateUser(this.state.currentItem);
        }

        if(result)
        {
            this.props.onChanged(this.state.currentItem, UserPanelCloseReason.AddOrUpdate);
        } else
        {
            alert("Fejl ved oprettelse eller opdatering af brugeren. Prøv igen senere.");
        }
    }

    private async deleteUser()
    {
        if(window.confirm("Er du sikker på, at du ønsker at slette denne bruger?"))
        {
            let result : boolean = false;
            if(this.props.selectedUser)
            {
                result = await this.userService.DeleteUser({ ID: this.state.currentItem.id + "" });
            } 
    
            if(result)
            {
                this.props.onChanged(this.state.currentItem, UserPanelCloseReason.Delete);
            } else
            {
                alert("Der er sket en fejl ved sletning af brugeren. Prøv igen senere.");
            }
        }        
    }
}