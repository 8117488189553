import { IDawaResult } from "../models";

export interface IDawaService
{
    Search(searchTerm: string) : Promise<IDawaResult[]>;
}

export class DawaServiceProvider implements IDawaService
{
    public async Search(searchTerm: string) : Promise<IDawaResult[]>
    {
        let result: IDawaResult[] = [];

        if(!searchTerm || searchTerm === "")
            return result;

        const requestOptions = {
            method: 'GET'
        };
    
        const response = await fetch(`https://dawa.aws.dk/adresser/autocomplete?q=${searchTerm}&per_side=25`, requestOptions);
        if(!response.ok)
        {
            return result;
        }

        return await response.json() as IDawaResult[];
    }
}

