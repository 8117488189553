import { CreateAuthHeader } from "../helpers/authentication-helper";
import { ICreateOrUpdateSupplierDTO, IUser, ILoginRequestDTO, IRequestNewPasswordDTO, ICreateOrUpdateUserDTO, IChangePasswordDTO, ISBPPersonDTO, ICreateSBPPersonDTO, IRegionDTO, ICertificationDTO, IDeleteUserDTO, IResetPassword } from "../models";

export interface IUserService
{
    CreateSupplier(currentItem: ICreateOrUpdateSupplierDTO): boolean | PromiseLike<boolean>;
    UpdateSupplier(currentItem: ICreateOrUpdateSupplierDTO): boolean | PromiseLike<boolean>; 
    DeleteSupplier(currentItem: ICreateOrUpdateSupplierDTO): boolean | PromiseLike<boolean>;     
    Login(model: ILoginRequestDTO) : Promise<IUser | undefined>;
    SignOut(): void;
    RequestNewPassword(model: IRequestNewPasswordDTO) : Promise<boolean>;
    ResetPassword(model: IResetPassword): Promise<boolean>;
    ChangePassword(model: IChangePasswordDTO): Promise<boolean>;
    GetUsers() : Promise<ICreateOrUpdateUserDTO[]>;
    CreateUser(model: ICreateOrUpdateUserDTO) : Promise<boolean>;
    UpdateUser(model: ICreateOrUpdateUserDTO) : Promise<boolean>;
    DeleteUser(model: IDeleteUserDTO) : Promise<boolean>;
    GetSuppliers(): Promise<ICreateOrUpdateSupplierDTO[]>;
    GetRegions(): Promise<IRegionDTO[]>;
    GetSBPPersons(supplierId: string): Promise<ISBPPersonDTO[]>;
    GetSBPPersonsForCurrentSupplier(): Promise<ISBPPersonDTO[]>;
    CreateSBPPerson(model: ICreateSBPPersonDTO) : Promise<boolean>;  
    DeleteSBPPerson(model: ISBPPersonDTO | undefined) : Promise<boolean>;
    GetCertificationsForSupplier(supplierId: string) : Promise<ICertificationDTO[]>;
    GetAllCertifications() : Promise<ICertificationDTO[]>;
    IsFirstTimeForUser(): boolean;
    dismissFirstTimeIntro(): void;
}

export class UserServiceProvider implements IUserService
{    
    public async Login(model: ILoginRequestDTO) : Promise<IUser | undefined>
    {
        if(!model.email || !model.password)
        {
            return undefined;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('authenticate', requestOptions);
        if(response.ok)
        {
            return await response.json() as IUser;           
        } 

        return undefined;
    }

    public SignOut()
    {
        localStorage.removeItem('hd_token');
        window.location.href = "/";
    }

    public async RequestNewPassword(model: IRequestNewPasswordDTO) : Promise<boolean>
    {
        if(!model.email)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('requestpassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async ResetPassword(model: IResetPassword): Promise<boolean>
    {
        if(!model.prFlowId || !model.password)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('resetpassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async ChangePassword(model: IChangePasswordDTO): Promise<boolean>
    {
        if(!model.existingPassword || !model.newPassword)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('changepassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async GetUsers() : Promise<ICreateOrUpdateUserDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch('users', requestOptions);
        if(!response.ok)
        {
        }

        let users = await response.json() as ICreateOrUpdateUserDTO[];

        for(let u of users)
        {
            u.regionName = u.region?.name;
            u.supplierName = u.supplier?.name;
        }

        return users;
    }

    public async CreateUser(model: ICreateOrUpdateUserDTO) : Promise<boolean>
    {   
        if(!model.name || !model.email || !model.password)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('users', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async UpdateUser(model: ICreateOrUpdateUserDTO) : Promise<boolean>
    {
        if(!model.name || !model.email)
        {
            return false;
        }

        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('users', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async DeleteUser(model: IDeleteUserDTO) : Promise<boolean>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('users', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async GetSuppliers() : Promise<ICreateOrUpdateSupplierDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch('suppliers', requestOptions);
        if(!response.ok)
        {
        }

        let suppliers = await response.json() as ICreateOrUpdateSupplierDTO[];

        for(let s of suppliers)
        {
            s.regionName = s.region?.name;
        }

        return suppliers;
    }

    public async GetCertificationsForSupplier(supplierId: string) : Promise<ICertificationDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch(`certifications?supplierId=${supplierId}`, requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return await response.json() as ICertificationDTO[];
    }

    public async GetAllCertifications() : Promise<ICertificationDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch(`certifications`, requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return await response.json() as ICertificationDTO[];
    }

    public async CreateSupplier(model: ICreateOrUpdateSupplierDTO) : Promise<boolean>
    {
        if(!model.name)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('suppliers', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async UpdateSupplier(model: ICreateOrUpdateSupplierDTO) : Promise<boolean>
    {
        if(!model.name)
        {
            return false;
        }

        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('suppliers', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async DeleteSupplier(model: ICreateOrUpdateSupplierDTO) : Promise<boolean>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('suppliers', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async GetSBPPersons(supplierId: string) : Promise<ISBPPersonDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch(`sbppersons?supplierId=${supplierId}`, requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return await response.json() as ISBPPersonDTO[];
    }

    public async GetSBPPersonsForCurrentSupplier() : Promise<ISBPPersonDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch(`sbppersons`, requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return await response.json() as ISBPPersonDTO[];
    }

    public async GetRegions() : Promise<IRegionDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch('regions', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return await response.json() as IRegionDTO[];
    }
    
    public async CreateSBPPerson(model: ICreateSBPPersonDTO) : Promise<boolean>
    {
        if(!model.supplierId || !model.name)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('sbppersons', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public async DeleteSBPPerson(model: ISBPPersonDTO | undefined) : Promise<boolean>
    {
        if(!model?.id)
        {
            return false;
        }

        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('sbppersons', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
        }

        return true;
    }

    public IsFirstTimeForUser()
    {
        return localStorage.getItem("hd_SkipIntro") == undefined;
    }

    public dismissFirstTimeIntro()
    {
        localStorage.setItem("hd_SkipIntro", "true");
    }
}