import { CreateAuthHeader } from '../helpers/authentication-helper';
import { IAssignmentDTO, IDeleteAssignmentDTO, IDocumentationDTO, IFileDTO } from "../models";
import { IAssignmentFileDTO } from "../models/DTO/IAssignmentFileDTO";

export interface IAssignmentService
{
    UploadDocumentation(model: IDocumentationDTO) : Promise<boolean>;
    GetAssignments() : Promise<IAssignmentDTO[]>;
    CreateAssignment(assignment: IAssignmentDTO) : Promise<string>;
    UpdateAssignment(assignment: IAssignmentDTO) : Promise<boolean>;
    DeleteAssignment(assignment: IDeleteAssignmentDTO) : Promise<boolean>;
    TakeNextPINFONo(assignment: IAssignmentDTO): Promise<number>;
    DownloadFile(assignment: IAssignmentDTO, fileName: string): Promise<string>;
    DeleteFile(assignment: IAssignmentDTO, file: IAssignmentFileDTO): Promise<void>;
    GetFilesForAssignment(assignment: IAssignmentDTO): Promise<IAssignmentFileDTO[]>;
}

export class AssignmentServiceProvider implements IAssignmentService
{
    public async GetAssignments() : Promise<IAssignmentDTO[]>
    {
        let assignments: IAssignmentDTO[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch('assignments', requestOptions);
        if(!response.ok)
        {
            return assignments;
        }

        assignments = await response.json() as IAssignmentDTO[];

        for(let a of assignments)
        {
            a.created = this.parseDate(a.created + "Z");

            if(a.trappingDate)
            {
                a.trappingDate = this.parseDate(a.trappingDate + "Z")
            }     
            
            if(a.approvedDate)
            {
                a.approvedDate = this.parseDate(a.approvedDate + "Z")
            }

            if(a.mapTimestamp)
            {
                a.mapTimestamp = this.parseDate(a.mapTimestamp + "Z")
            }

            if(a.documentationTimestamp)
            {
                a.documentationTimestamp = this.parseDate(a.documentationTimestamp + "Z")
            }

            if(a.files)
            {
                a.files = a.files.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            }
        }

        return assignments;
    }

    // From UTC to local time and supporting Safari on iOS
    private parseDate(dateAsString: string)
    {
        let parts : string[] = dateAsString.split(/[^0-9]/);
        return new Date(Date.UTC(
            parseInt(parts[0]),
            parseInt(parts[1]) - 1,
            parseInt(parts[2]),
            parseInt(parts[3]),
            parseInt(parts[4]),
            parseInt(parts[5])));
    }

    public async CreateAssignment(assignment: IAssignmentDTO) : Promise<string>
    {
        //Filter out 'Documentation' to keep payload as small as possible
        if(assignment.files)
        {
            assignment.files = assignment.files.filter(d => d.type != "Documentation");
        }
        
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(assignment)
        };
    
        const response = await fetch('assignments', requestOptions);
        if(!response.ok)
        {
            return "";
        }        

        let res = await response.text();

        return res;
    }

    public async UpdateAssignment(assignment: IAssignmentDTO) : Promise<boolean>
    {
        //Filter out 'Documentation' to keep payload as small as possible
        if(assignment.files)
        {
            assignment.files = assignment.files.filter(d => d.type != "Documentation");
        }

        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(assignment)
        };
    
        const response = await fetch('assignments', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async DeleteAssignment(model: IDeleteAssignmentDTO) : Promise<boolean>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('assignments', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async TakeNextPINFONo(assignment: IAssignmentDTO) : Promise<number>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch(`pinfo?assignmentId=${assignment.id ? assignment.id : ""}`, requestOptions);
        if(!response.ok)
        {
            return -1;
        }

        return await response.json() as number;
    }
    
    public async UploadDocumentation(assignment: IDocumentationDTO) : Promise<boolean>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(assignment)
        };
    
        const response = await fetch('documentations', requestOptions);
        if(!response.ok)
        {
            return false;
        }        

        return true;
    }

    public async GetFilesForAssignment(assignment: IAssignmentDTO) : Promise<IAssignmentFileDTO[]>
    {
        let files: IAssignmentFileDTO[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`files?assignmentId=${assignment.id}`, requestOptions);
        if(!response.ok)
        {
            return files;
        }

        files = await response.json() as IAssignmentFileDTO[];
        return files.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    public async DownloadFile(assignment: IAssignmentDTO, fileName: string) : Promise<string>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`download?assignmentId=${assignment.id}&fileName=${encodeURIComponent(fileName)}`, requestOptions);
        if(!response.ok)
        {
            return "";
        }        

        let blob = await response.blob();
        
        return window.URL.createObjectURL(blob);
    }

    public async DeleteFile(assignment: IAssignmentDTO, file: IAssignmentFileDTO) : Promise<void>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader(),
            body: JSON.stringify({ assignmentID: assignment.id, id: file.id, filename: file.name } as IFileDTO)
        };
    
        const response = await fetch(`files`, requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl under sletningen af filen. Prøv igen senere.');
        }
    }
}