import { IContextualMenuProps, CommandButton, CompoundButton, Dialog, DialogContent, DialogType, DialogFooter } from '@fluentui/react';
import React from 'react';
import App from '../../App';
import { IUserService, Services, UserServiceProvider } from '../../services';
import styles from './AppLayout.module.scss';
import { AnnouncementMessageBar } from './AnnouncementMessageBar';

interface IAppLayoutProps
{

}

interface IAppLayoutState
{
  showIntro: boolean;
}

export class AppLayout extends React.Component<IAppLayoutProps, IAppLayoutState> {  
  private userService : IUserService;
  
  constructor(props : IAppLayoutProps) {
    super(props);
    
    this.userService = Services.UserService.Initialize(new UserServiceProvider());

    this.state =
    {
      showIntro: this.userService.IsFirstTimeForUser()
    }
  }

  public render(): React.ReactElement<{}> {
    let userMenuProps: IContextualMenuProps = {
      items: [
        {
          key: 'changePassword',
          text: 'Skift adgangskode',
          iconProps: { iconName: 'PasswordField' },
          href:"/skift-adgangskode"
        },      
        {
          key: 'signOut',
          text: 'Log af',
          iconProps: { iconName: 'SignOut' },
          onClick: (ev) => {
            this.userService.SignOut();
          }
        },
      ]
    };

    if(App.IsUserInRole("DL") || App.IsUserInRole("Admin"))
    {
      userMenuProps.items.splice(1, 0, {
        key: 'assignments',
        text: 'Opgaveoversigt',
        iconProps: { iconName: 'TaskManager' },
        href:"/"
      });

      userMenuProps.items.splice(2, 0, {
        key: 'userAdministration',
        text: 'Brugeradministration',
        iconProps: { iconName: 'Contact' },
        href:"/brugeradministration"
      });

      userMenuProps.items.splice(3, 0, {
        key: 'linkInfoPageInternally',
        text: 'Information til DL`ere',
        iconProps: { iconName: 'BookAnswers' },
        href:"https://ddh4.sharepoint.com/sites/Intranet/SitePages/Flisportalen.aspx",
        target: "_blank"  
      });
    }

    if(App.IsUserInRole("Admin"))
    {
      userMenuProps.items.splice(4, 0, {
        key: 'announcements',
        text: 'Beskeder',
        iconProps: { iconName: 'Message' },
        href:"/beskeder"
      });
    }

    return (      
      <div className={styles.layoutContainer}>
        <div className={styles.header}>
          { App.IsAuthenticated && 
          <React.Fragment>              
              <CommandButton className={styles.userMenu} text={App.Username} menuProps={userMenuProps} />                          

              { this.state.showIntro &&
              
                <Dialog hidden={false} minWidth={window.innerWidth * 0.6} dialogContentProps={{title: "Flisleverandør til HedeDanmark", isMultiline: true, type: DialogType.largeHeader}}>
                <DialogContent>
                  <div>
                    <h4>Intro</h4>
  
                    <div>I Danmark har vi indført lovgivning om bæredygtighed af flis til energi. Det betyder at leverandører af flis skal dokumentere, at produktionen er foregået efter de gældende bæredygtighedsregler. HedeDanmark bruger certificeringsordningen SBP til at dokumentere bæredygtigheden af vores flisleverancer.
                    Når du sælger flis eller flistræ til HedeDanmark, vil du blive bedt om at overholde nogle regler og aflevere noget information til os, så vi kan tage dine leverancer ind under vores SBP-certificering.
                    Alle der arbejder på en opgave skal have gennemført HedeDanmarks online SBP-kursus. Din HedeDanmark skovfoged eller driftsleder kan hjælpe dig med at få adgang til kurset.</div>             
                    <br/>
                    <h4>Dokumentation</h4>
                    <div>
                      <ul>
                        <li>Kort 1 som viser hvilke naturhensyn og kulturminder, der findes i det relevante produktionsområde.</li>
                        <li>Kort 2 som viser HNV-status på produktionsområdet.</li>
                      </ul>              
                    <p>De to kort skal laves før fældearbejdet begynder og skal være tilgængelige for alle der udfører arbejde på opgaven. Bæredygtighedsdokumentationen skal udfyldes, før du overdrager flisen til HedeDanmark.</p>
                    </div>
                  </div>
                </DialogContent>
                <DialogFooter>
                  <CompoundButton iconProps={{iconName: "Help"}} secondaryText="Gå til supportportalen" onClick={() => { this.onDismissFirstTimeIntro(); window.open('https://flisportal-support.azurewebsites.net', '_blank'); }}>Behov for hjælp?</CompoundButton> 
                  <CompoundButton primary iconProps={{iconName: "CheckMark"}} secondaryText="Begynd at bruge Flisportalen" onClick={() => { this.onDismissFirstTimeIntro(); }}>Jeg har forstået</CompoundButton>                
                </DialogFooter>
                </Dialog> 
              }
              </React.Fragment>
          }


          
        </div>
        <div className={styles.container}>     
          { App.IsAuthenticated && <AnnouncementMessageBar />  }
          <a href="/"><img alt="HedeDanmark logo" className={styles.logo} src="/images/logo.png?rev=1.0" /></a>
            <div className={styles.headerimage}>
              <img alt="HedeDanmark Flis baggrund" src="/images/topbanner.jpg?rev=1.0" />
              <svg width="1440px" height="203px" viewBox="0 0 1440 203" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                          <g id="shop_forside_1" transform="translate(0.000000, -376.000000)" fill="#FFFFFF">
                                              <path d="M0,578.015878 L1440,578.015878 C1885.14583,319.758548 1885.14583,252.418132 1440,375.994629 C994.854167,499.571126 514.854167,566.911543 0,578.015878 Z" id="Path"></path>
                                          </g>
                                      </g>
                                  </svg>
            </div>
            <div className={styles.appName}>Flisportalen</div>
            <div className={styles.helpSection}>
              <CompoundButton  iconProps={{iconName: "Help"}} secondaryText="Gå til supportportalen" onClick={() => { window.open('https://flisportal-support.azurewebsites.net', '_blank'); }}>Behov for hjælp?</CompoundButton> 
            </div>
          <div className={styles.contentWrapper}>
            {this.props.children}
          </div>          
        </div>
      </div>
    );
  }

  private onDismissFirstTimeIntro()
  {
    this.userService.dismissFirstTimeIntro();

    this.setState({showIntro: false});
  }
}
