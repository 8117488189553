import { LatLng } from "leaflet"

export interface IWorkingArea
{
    identifier: string;
    coordinates: LatLng[];
    name?: string;
    color?: string;
    isEditable: boolean;
    type: WorkingAreaType;
}

export enum WorkingAreaType
{
  WorkingArea,
  Polyline
}