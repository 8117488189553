import * as React from 'react';
import { Callout, Dialog, DialogFooter, DirectionalHint, Dropdown, IDropdownOption, PrimaryButton, SearchBox, TextField } from '@fluentui/react';
import styles from './AddressAutoComplete.module.scss';
import { DawaServiceProvider, IDawaService, Services } from '../../../services';
import { IDawaResult } from '../../../models';

export interface IAddressAutoCompleteProps {
    onAddressSelected(x: number, y: number): void;
}

export interface IAddressAutoCompleteState {
    showSuggestions: boolean;
    suggestions: IDawaResult[];
}

export default class AddressAutoComplete extends React.Component<IAddressAutoCompleteProps, IAddressAutoCompleteState> {
    private dawaService : IDawaService;
    constructor(props: IAddressAutoCompleteProps) {
        super(props);
        
        this.dawaService = Services.DawaService.Initialize(new DawaServiceProvider());

        this.state = {
            showSuggestions: false,
            suggestions: [] as IDawaResult[]
        };
    }
    
    public render(): React.ReactElement<IAddressAutoCompleteProps> {  
        return (<div>
                    <SearchBox 
                        className={styles.searchBox} 
                        placeholder="Søg efter en adresse i Danmark" 
                        onSearch={(searchTerm: string) => { this.searchDawa(searchTerm); }}
                        onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { this.searchDawa(newValue + ""); }}
                        onClear={ev => { this.searchDawa(""); }}
                        />        
                        {this.state.showSuggestions &&                 
                            <div className={styles.suggestions}>
                                {this.state.suggestions.map((v, i, a) => { return <div className={styles.suggestionItem} onClick={() => { this.setState({showSuggestions: false}); this.props.onAddressSelected(v.adresse.x, v.adresse.y); }} key={`addressSuggestions_${i}`} >{v.tekst}</div> })}
                            </div>   
                        }
                </div>
        );
    }

    private delayTimer;
    private async searchDawa(searchTerm: string)
    {
        clearTimeout(this.delayTimer);

        this.delayTimer = setTimeout(async () => {
            let result = await this.dawaService.Search(searchTerm);
            this.setState({suggestions: result, showSuggestions: true});
        }, 500);
    }
}