import * as React from 'react';
import { Dialog, DialogFooter, Label, PrimaryButton, TextField } from '@fluentui/react';
import { IWorkingArea } from '../../../../models';
import styles from './WorkingAreaDialog.module.scss';

export interface IWorkingAreaDialogProps {
    currentWorkingArea: IWorkingArea;
    onSaveClicked(identifier: string, name: string, color: string): void;
}

export interface IWorkingAreaDialogState {
    name: string;
    color: string;
    colors: string[];
}

export default class WorkingAreaDialog extends React.Component<IWorkingAreaDialogProps, IWorkingAreaDialogState> {

    constructor(props: IWorkingAreaDialogProps) {
        super(props);
        
        this.state = {
          name: props.currentWorkingArea.name ? props.currentWorkingArea.name + "" : "",
          color: props.currentWorkingArea.color ? props.currentWorkingArea.color + "" : "#4d6074",
            colors: ["#16a085", "#2ecc71", "#2980b9", "#3498db", "#9b59b6", "#e74c3c", "#f39c12", "#f1c40f", "#2c3e50", "#7f8c8d", "#bdc3c7", "transparent"] as string[]
        };
    }
    
    public render(): React.ReactElement<IWorkingAreaDialogProps> {  
        return (<Dialog
            hidden={false}
            minWidth={300}            
            title="Navn på arbejdsområde">              
              <TextField label="Navn" value={this.state.name} required={true} onChange={(ev, newValue) => { if(!newValue || newValue && newValue.length <=20) this.setState({name: newValue + ""}); }} />              
              <Label>Flade farve</Label>
              <div className={styles.colorPickerContainer}>
                {this.state.colors.map((val, i, a ) => {
                  return <div key={`colorBox_${val}`} className={styles.colorBox} style={{backgroundColor: val, border: this.state.color == val ? "2px solid rgba(0,0,0, 0.25)" : val == "transparent" ? "2px dotted red" : "none"}} onClick={() => { this.setState({color: val}); }} />
                })}
              </div>                
              <DialogFooter>
            <PrimaryButton text="Gem på kortet" disabled={this.state.name == "" || this.state.color == ""} onClick={() => { this.props.onSaveClicked(this.props.currentWorkingArea.identifier, this.state.name, this.state.color) } } />
          </DialogFooter>
          </Dialog>);
    }
}