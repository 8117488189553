import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { IAnnouncementDTO } from "../../models";
import { IAnnouncementService, Services, AnnouncementServiceProvider } from '../../services';

export interface IAnnouncementMessageBarProps
{
}

export interface IAnnouncementMessageBarState
{
    announcements?: IAnnouncementDTO[];
}

export class AnnouncementMessageBar extends React.Component<IAnnouncementMessageBarProps, IAnnouncementMessageBarState> {
    private announcementService : IAnnouncementService;

    constructor(props : IAnnouncementMessageBarProps) {
        super(props);
        
        this.announcementService = Services.AnnouncementService.Initialize(new AnnouncementServiceProvider());
    
        this.state = { };        
    }

    public componentDidMount(): void {
      this.load();
    }

    private async load()
    {
        let announcements = await this.announcementService.GetAnnouncements();

        this.setState({announcements: announcements});
    }

    public render(): React.ReactElement<IAnnouncementMessageBarProps> {
        if(!this.state.announcements || this.state.announcements.length == 0)
        {
            return <React.Fragment />;
        }
        
        let components = this.state.announcements.map((val, i, a) => {
            return <MessageBar key={val.id} messageBarType={MessageBarType.warning}>
                {val.text}
            </MessageBar>
         });

         return <div style={{marginTop:"3px"}}>{components}</div>;
    }
}