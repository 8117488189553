import * as React from 'react';
import { Dialog, DialogFooter, DirectionalHint, Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { IStackMarker } from '../../../../models/Maps/IStackMarker';
import { Direction } from 'leaflet';

export interface IStackDialogProps {
    currentStack: IStackMarker;
    onSaveClicked(identifier: string, no: string, size: number, treeSpecies: string, tooltipPosition: Direction): void;
}

export interface IStackDialogState {
    no: string; //must be number, so will keep as string to ensure previously created stacks (backward comp.)
    size: number;
    treeSpecices: string;
    tooltipPosition: string;
}

export default class StackDialog extends React.Component<IStackDialogProps, IStackDialogState> {

    constructor(props: IStackDialogProps) {
        super(props);
        
        this.state = {
            no: this.props.currentStack.no ? this.props.currentStack.no : "",
            size: this.props.currentStack.size ? this.props.currentStack.size : 0,
            treeSpecices: this.props.currentStack.treeSpecices ? this.props.currentStack.treeSpecices : "",
            tooltipPosition: this.props.currentStack.tooltipPosition ? this.props.currentStack.tooltipPosition : "top"
        };
    }
    
    public render(): React.ReactElement<IStackDialogProps> {  
        return (<Dialog
            hidden={false}
            minWidth={200}            
            title="Angiv stak informationer">
              <TextField label="Stak nummer" pattern="[0-9]*" description={"Stak nummer skal være et helt tal"} value={this.state.no} onChange={(ev, newValue) => { 
                  let v = newValue ? newValue : "0";
                  
                  if((ev.target as any).validity.valid) {
                    this.setState({no: parseFloat(v + "") + ""});  }}
                  }                    
              />
              <TextField label="Stak størrelse" pattern="[0-9]*" description={"Mængden skal være et helt tal"} value={this.state.size + ""} suffix="rm" onChange={(ev, newValue) => { 
                  let v = newValue ? newValue : "0";
                  
                  if((ev.target as any).validity.valid) 
                  {
                      this.setState({size: parseFloat(v + "") }); }}
                  }
              />
              <Dropdown
                placeholder="Vælg fra listen"
                label="Træart"
                selectedKey={this.state.treeSpecices}
                options={[
                    { key: 'Løv', text: 'Løv' },
                    { key: 'Nål', text: 'Nål' },
                    { key: 'Blandet', text: 'Blandet' },
                    { key: 'Pil/poppel', text: 'Pil/poppel' }
                ]}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ treeSpecices: option?.key + "" }); }}
                /> 
                <Dropdown
                placeholder="Vælg fra listen"
                label="Tekst placering"
                selectedKey={this.state.tooltipPosition}
                options={[
                    { key: 'top', text: 'I toppen' },
                    { key: 'bottom', text: 'I bunden' },
                    { key: 'left', text: 'Til venstre' },
                    { key: 'right', text: 'Til højre' }
                ]}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ tooltipPosition: option?.key + "" }); }}
                /> 
              <DialogFooter>
            <PrimaryButton text="Gem på kortet" onClick={() => { this.props.onSaveClicked(this.props.currentStack.identifier, this.state.no, this.state.size, this.state.treeSpecices, this.state.tooltipPosition as Direction) } } />
          </DialogFooter>
          </Dialog>);
    }
}