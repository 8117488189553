import { IAnnouncementService, IAssignmentService, IDawaService, IUserService } from '../services'

export namespace Services
{
    export class UserService
    {
        // Singleton pattern
        private static provider: IUserService;
        public static Initialize(provider: IUserService): IUserService {
            if (provider == null && this.provider)
                return this.provider;

            this.provider = provider;

            return this.provider;
        }
    }

    export class AssignmentService
    {
        // Singleton pattern
        private static provider: IAssignmentService;
        public static Initialize(provider: IAssignmentService): IAssignmentService {
            if (provider == null && this.provider)
                return this.provider;

            this.provider = provider;

            return this.provider;
        }
    }

    export class DawaService
    {
        // Singleton pattern
        private static provider: IDawaService;
        public static Initialize(provider: IDawaService): IDawaService {
            if (provider == null && this.provider)
                return this.provider;

            this.provider = provider;

            return this.provider;
        }
    }

    export class AnnouncementService
    {
        // Singleton pattern
        private static provider: IAnnouncementService;
        public static Initialize(provider: IAnnouncementService): IAnnouncementService {
            if (provider == null && this.provider)
                return this.provider;

            this.provider = provider;

            return this.provider;
        }
    }
}