import * as React from 'react';
import { CommandBar, DefaultButton, Dialog, DialogFooter, ICommandBarItemProps, Link, List, PrimaryButton, ProgressIndicator, TeachingBubble } from '@fluentui/react';
import { Map, TileLayer, Marker, Popup, LayersControl, FeatureGroup, Polyline, WMSTileLayer, Polygon, Tooltip, ScaleControl, Rectangle } from 'react-leaflet';
import { IAssignmentDTO, IContainerSpot, ILayer, IMapData, IRoad, IStackMarker, ITextArea, IWorkingArea, MapDrawingColors, RoadType, WorkingAreaType } from '../../../models';
import { MapUtilities } from '../../../helpers/Utilities';
import styles from './DocumentationDialog.module.scss';
import QRCode from "react-qr-code";
import L from "leaflet";
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import { AssignmentServiceProvider, IAssignmentService, Services } from '../../../services';
import { IAssignmentFileDTO } from '../../../models/DTO/IAssignmentFileDTO';
import MapCacheLayer from '../Maps/MapCacheLayer';

export interface IDocumentationDialogProps {
  currentItem: IAssignmentDTO;
  onDismiss(): void;
  onSaved(newFiles: IAssignmentFileDTO[]): void;
}

export interface IDocumentationDialogState {
  step: DocumentationSteps;

  mapWidth: number;
  mapData: IMapData;

  backgroundLayers: ILayer[];
  backgroundLayer: string;
  layers: ILayer[];
  selectedLayers: string[];

  currentZoomLevel: number;
  miniMapCenter: any;

  showStartInfo: boolean;
  showStep2Info: boolean;
  workingOnIt: boolean;

  workingOnItText: string;
  saveButtonText: string;

  mapBounds: any;
  files: IAssignmentFileDTO[];
}

enum DocumentationSteps
{
  Documentation = 1,
  StackMap = 2
}

export default class DocumentationDialog extends React.Component<IDocumentationDialogProps, IDocumentationDialogState> {
  private minZoomLevelForLayers = 8;
  private minZoomLevelForDrawingObjects = 7;
  private lineOrBorderWeight = 9;
  private assignmentService : IAssignmentService;
  private mapRef : any;
  private groupRef : any;

    constructor(props: IDocumentationDialogProps) {
        super(props);
        
        this.assignmentService = Services.AssignmentService.Initialize(new AssignmentServiceProvider());

        MapUtilities.ConfigureLeafLet();  
        window.addEventListener('resize', this.handleResize.bind(this));

        let mapData = JSON.parse(props.currentItem.mapData + "") as IMapData;

        this.state = {
          step: DocumentationSteps.Documentation,
          mapWidth: this.getMapWidth(),
          currentZoomLevel: 13,
          mapData: mapData,
          backgroundLayers: [
            { type:"wmts",name: "Luftfoto", attribution: "Orto", url: "https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/wmts?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A&layer=orto_foraar_wmts&style=default&tilematrixset=KortforsyningTilingDK&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/jpeg&TileMatrix={z}&TileCol={x}&TileRow={y}"} as ILayer,            
            { type:"wms", name: "Topo DTK25", attribution: "Topo DTK25", transparent: "TRUE", layers:"dtk25_2021", version: "1.3.0", url: "https://api.dataforsyningen.dk/dtk_25_DAF?token=ea2729ee97000593afc48e6feb11dfaf"} as ILayer,              
          ] as ILayer[],
          backgroundLayer: "Luftfoto",
          layers: [              
            { name: "Beskyttet natur §3", layers: "BES_NATURTYPER,bes_vandloeb", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_beskyttetnatur.png"} as ILayer,
            { name: "Fredninger", layers: "fredede_omr", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_fredninger.png"} as ILayer,            
            { name: "Fortidsminder", layers: "v_lokalitet_all", url: "https://www.kulturarv.dk/wms", enabled: true, signature: "Signatur_Fortidsminder.png", styles: "v_lokalitet_protected"} as ILayer,
            { name: "Beskyttelseslinjer", layers: "v_reg_lokalitet_beskyttelse_areal", url: "https://www.kulturarv.dk/wms", enabled: true, signature: "Signatur_Beskyttelseslinjer.png"} as ILayer,
            { name: "Beskyttede sten- og jordiger", layers: "BES_STEN_JORDDIGER", url: "https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", enabled: true, signature: "Signatur_beskyttedestenjorddiger.png"} as ILayer,
            { name: "Natura 2000 områder", layers: "theme-pg-natura_2000_omraader", url: "https://miljoegis.mim.dk/wms?servicename=miljoegis-natura2000_wms", enabled: true, signature: "Signatur_natura2000omraader.png"} as ILayer,
            { name: "HNV", layers: "theme-plangroen_hnvskov", url: "https://miljoegis.mim.dk/wms?servicename=miljoegis-plangroendk_wms", enabled: true, signature: "Signatur_hnvskov.png"} as ILayer,
            { name: "Fredskovsarealer", layers: "FredskovsLinje_Gaeldende", url: "https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?username=BUDFIWOUSX&password=2nq8kyU7Ne5gHv!!A", enabled: true, signature: "Signatur_fredskov.png"} as ILayer
          ],
          selectedLayers: [] as string[],
          showStartInfo: true,
          showStep2Info: false,
          workingOnIt: false,
          mapBounds: undefined,
          saveButtonText: "Gem dokumentation",
          miniMapCenter: mapData.defaultPosition,
          workingOnItText: "Vi arbejder på sagen",
          files: []
        };
    }

    public render(): React.ReactElement<IDocumentationDialogProps> {
      const CommandBar_Map_FarItems: ICommandBarItemProps[] = [    
        {
          key: 'closeWithoutSaving',
          text: 'Luk uden at gemme',
          iconProps: { iconName: 'ChromeClose' },
          onClick: () => { this.props.onDismiss(); }
        },  
        {
          key: 'saveOrClose',
          text: this.state.saveButtonText,
          iconProps: { iconName: 'Save' },
          className: styles.buttonSaveAndClose,
          onClick: () => { this.saveDocumentation(); }             
        }               
      ];

      return (<React.Fragment>
        <Dialog
          hidden={false}
          modalProps={{isBlocking: true }}
          minWidth={this.state.mapWidth}          
          title={`Bæredygtighedsdokumentation`}>   
            <React.Fragment> 
              <CommandBar className={styles.commandBarMap} styles={{ root: { paddingLeft: "0px", height: "38px" } }} farItems={CommandBar_Map_FarItems} items={[]} />                
              <div id="documentationDialogOuter" className={styles.documentationDialog}>   
                <img className={styles.logo} src="/images/logo.png?rev=1.0" />           
                <div className={styles.leftMenu}>                  
                  {this.renderLeftMenu()}
                </div>
                <div className={styles.seperator}></div>
                <div className={styles.mapContainer}>
                  <div className={styles.stamData}>
                    <div><b>Opgavenavn:</b> {this.props.currentItem.orderName}</div>
                    <div>|</div>
                    <div><b>HD AO nr.:</b> {this.props.currentItem.hdaoNo}</div>
                    <div>|</div>
                    <div style={{paddingRight:"10px"}}>Udarbejdet {new Date().toLocaleDateString('da-DK')}</div>
                  </div>
                  {this.state.step == DocumentationSteps.StackMap && this.state.mapBounds != undefined && 
                    <div className={styles.miniMap}>
                        <Map
                          style={{ height: 200, width: 200 }}
                          center={this.state.miniMapCenter}
                          zoom={11}
                          dragging={true}
                          doubleClickZoom={false}
                          scrollWheelZoom={true}
                          attributionControl={false}
                          zoomControl={false}>
                          <WMSTileLayer
                              layers={"dtk250_2021"}                                
                              transparent={"TRUE" as any}
                              styles={""}
                              version={"1.3.0"}
                              format={'image/png'}
                              url={"https://api.dataforsyningen.dk/dtk_250_DAF?token=ea2729ee97000593afc48e6feb11dfaf"} />
                          <Rectangle bounds={this.state.mapBounds} pathOptions={{ weight: 1 }} />                          
                        </Map>
                    </div>
                  }
                  <Map ondragend={() => { this.setMapBounds(); } } onzoomend={this.onZoomEnd.bind(this)} zoomControl={true} ref={(ref : any) => { this.mapRef = ref; }} minZoom={7} maxZoom={13} bounceAtZoomLimits={true} doubleClickZoom={false} crs={MapUtilities.CRS} className={styles.map} center={this.state.mapData?.defaultPosition} zoom={this.state.mapData?.recentZoomLevel} scrollWheelZoom={true}>
                    <ScaleControl imperial={false} />
                    <LayersControl collapsed={false} position="topright">
                        {this.state.backgroundLayers.map((layer : ILayer, idx) => 
                          <LayersControl.BaseLayer key={`layer-${idx}`} checked={this.state.backgroundLayer === layer.name} name={layer.name}>
                            { layer.type == "wmts" &&
                                <TileLayer
                                  minZoom={3}
                                  maxZoom={13}
                                  crossOrigin={true}
                                  attribution={layer.attribution} 
                                  url={layer.url} />
                            }
                            { layer.type == "wms" && 
                              <WMSTileLayer
                              layers={layer.layers}                                
                              transparent={"FALSE" as any}
                              styles={""}
                              version={"1.1.1"}
                              format={'image/png'}
                              url={layer.url} />
                            }
                            </LayersControl.BaseLayer>
                          )}     
                          {!this.state.showStartInfo && this.state.layers.map((layer : ILayer, idx) => 
                          <LayersControl.Overlay key={`overlay-${idx}`} name={layer.name} checked={layer.enabled} >
                             <WMSTileLayer
                              layers={layer.layers}
                              minZoom={this.minZoomLevelForLayers}
                              transparent={ (layer.name == "Matrikel" || layer.name == "Fredskovsarealer") ? "TRUE" as any : true}   //Some WMS api requires TRUE value in uppercase                           
                              format={'image/png'}
                              url={layer.url} />                  
                            </LayersControl.Overlay>
                          )}      
                        </LayersControl>
                    <FeatureGroup ref={(ref : any) => { this.groupRef = ref; this.setMapBounds(); }}>                                      
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.fixedTaskPosition && this.renderFixedTaskPosition()}
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.stacks?.map((stack : IStackMarker) => this.renderStack(stack))}
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.workingAreas?.map((workingarea : IWorkingArea) => this.renderWorkingArea(workingarea))} 
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.roads?.map((road : IRoad) => this.renderRoad(road))}                    
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.containerSpots?.map((marker : IContainerSpot) => this.renderContainerSpot(marker))}  
                        {this.state.currentZoomLevel >= this.minZoomLevelForDrawingObjects && this.state.mapData.textAreas?.map((textArea : ITextArea) => this.renderTextArea(textArea))}             
                    </FeatureGroup>
                    <MapCacheLayer />
                  </Map>                  
                </div>
              </div>              
              { this.state.showStartInfo && 
                <React.Fragment>
                  <div id="bubbleCenterPosition" className={styles.bubbleCenterPosition}>

                  </div>
                  <TeachingBubble
                    target={`#bubbleCenterPosition`}                    
                    primaryButtonProps={{
                      children: 'Forstået!',
                      onClick: () => { this.setState({showStartInfo: false}); },
                    }}       
                    secondaryButtonProps={{
                      children: 'Gå direkte til oversigtskort',
                      onClick: () => { this.goToStep2([] as IAssignmentFileDTO[]); },
                    }}                
                    hasSmallHeadline={true}
                    hasCloseButton={false}
                    headline="Step 1: Bæredygtighed - Panorer kortet inden du fortsætter"                
                  >
                    Sørg for at alle nødvendige elementer er synlige på kortet og zoom tilstrækkeligt ind, inden du gemmer bæredygtighedsdokumentationen.
                  </TeachingBubble>
                </React.Fragment>
              }
              { this.state.showStep2Info && 
                <React.Fragment>
                  <div id="bubbleCenterPosition" className={styles.bubbleCenterPosition}>

                  </div>
                  <TeachingBubble
                    target={`#bubbleCenterPosition`}                    
                    primaryButtonProps={{
                      children: 'Forstået!',
                      onClick: () => { this.setState({showStep2Info: false}); },
                    }}                
                    hasSmallHeadline={true}
                    hasCloseButton={false}
                    headline="Step 2: Oversigtskort - Panorer kortet inden du fortsætter"                
                  >
                    Sørg for at alle nødvendige elementer er synlige på kortet og zoom tilstrækkeligt ind, inden du gemmer oversigtskortet.
                  </TeachingBubble>
                </React.Fragment>
              }
            </React.Fragment> 
        </Dialog>
        {this.state.workingOnIt && 
          <Dialog minWidth={500} title={"Vent venligst..."} hidden={false} modalProps={{isBlocking: true}}>
            <ProgressIndicator label={this.state.workingOnItText} description="Det kan tage flere minutter. Denne boks lukker automatisk, når dokumentationen er gemt på opgaven." />
          </Dialog>
        }
      </React.Fragment>);
  }

  private setMapBounds()
  {
    if(this.mapRef != null && this.groupRef != null)
    {
      const map = this.mapRef.leafletElement;
      const mapBounds = map.getBounds();
      const mapCenter = map.getCenter();

      this.setState({mapBounds: mapBounds, miniMapCenter: mapCenter});
    }
  }

  private goToStep2(newFiles)
  {
    let layers = [...this.state.layers];

    layers.forEach((part, index, theArray) => {
      layers[index].enabled = false;
    });

    this.setState({backgroundLayer: "Topo DTK25", layers: layers, files: newFiles, workingOnIt: false, showStartInfo:false, showStep2Info: true, saveButtonText: "Gem oversigtskort", step: 2});
  }

  private async saveDocumentation()
  {
    switch(this.state.step)
    {
      case DocumentationSteps.Documentation:
        {
          this.setState({workingOnIt: true, workingOnItText: "Udarbejder bæredygtighedskort inkl. HNV"}, async () => {
            let map1 = await this.convertCurrentMapToPdf();
      
            let layers = [...this.state.layers];
            let hnvIndex = layers.findIndex(d => d.name == "HNV");
            layers[hnvIndex].enabled = false;
      
            this.setState({layers: layers, workingOnItText: "Udarbejder bæredygtighedskort"}, async () => {
              let map2 = await this.convertCurrentMapToPdf();
      
              let newFiles = [
                {name: this.createFileName("Bæredygtighedsdokumentation inkl. HNV.pdf"), type: "Documentation", content: map1}, 
                {name: this.createFileName("Bæredygtighedsdokumentation.pdf"), type: "Documentation", content: map2}
              ] as IAssignmentFileDTO[];              
              
              let result = await this.assignmentService.UploadDocumentation({ assignmentId: this.props.currentItem.id, files: newFiles});
              if(!result)
              {
                alert('Der er sket en uventet fejl under udarbejdelse af bæredygtighedskort. Prøv venligst igen senere.');
                this.props.onDismiss();
              } else
              {
                this.goToStep2(newFiles);
              }
            });
          });
        }
        break;
      case DocumentationSteps.StackMap:
        {
          this.setState({workingOnIt: true, workingOnItText: "Udarbejder oversigtskort"}, async () => {
            let map3 = await this.convertCurrentMapToPdf();
                      
            let allFiles = this.state.files;

            allFiles.forEach((part, index, theArray) => {
              allFiles[index].KeepDuringSoftDelete = true;
              allFiles[index].content = undefined; //clear content to reduce size in http post
            });

            allFiles.push({name: this.createFileName("Oversigtskort.pdf"), type: "Documentation", content: map3} as IAssignmentFileDTO);
            
            let result = await this.assignmentService.UploadDocumentation({ assignmentId: this.props.currentItem.id, files: allFiles});
            
            if(!result)
            {
              alert('Der er sket en uventet fejl under udarbejdelse af oversigtskort. Prøv venligst igen senere.');
              this.props.onDismiss();
            } else {
              this.props.onSaved(allFiles);
            }            
          });          
        }
        break;
    }    
  }

  private createFileName(filename: string)
  {
    return `ID${this.props.currentItem.hdaoNo == null ? "" : this.props.currentItem.hdaoNo} ${this.props.currentItem.orderName} - ${filename}`;
  }

  private sleep(ms) 
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async convertCurrentMapToPdf()
  {
    const maps = document.querySelectorAll(".leaflet-overlay-pane .leaflet-zoom-animated");
    let originals = [] as any;

    for (let i = 0; i < maps.length; ++i)
    {
      let map = maps[i] as any;

      originals.push({top: map.style.top, left: map.style.left } as any);

      const coordinates = map.style.transform.split("(")[1].split(")")[0].split(",");
      map.style.top = -1 * parseInt(coordinates[1].replace("px", ""), 10) + "px";
      map.style.left = -1 * parseInt(coordinates[0].replace("px", ""), 10) + "px";
    }

    await this.sleep(200);

    let v : HTMLElement = document.querySelector("#documentationDialogOuter") as HTMLElement;

    let canvasOne = await html2canvas(v, {useCORS: true});

    //Reset
    for (let i = 0; i < maps.length; ++i)
    {
      let map = maps[i] as any;
      
      map.style.top = originals[i].top;
      map.style.left = originals[i].left;
    }
    
    var doc1 = canvasOne.toDataURL();
    
    const pdf = new jsPDF("landscape", "px", "a4");
    const imgProps= pdf.getImageProperties(doc1);
    const pdfWidth = pdf.internal.pageSize.getWidth()-5;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(doc1, 5, 5, pdfWidth - 5, pdfHeight);

    if(this.state.step == DocumentationSteps.StackMap && this.state.mapData.fixedTaskPosition)
    {
      pdf.textWithLink('Åbn i Google Maps', 10, pdfHeight+50, { url: `https://www.google.com/maps/search/?api=1&query=${this.state.mapData.fixedTaskPosition.position?.lat},${this.state.mapData.fixedTaskPosition.position?.lng}` });
    }

    //TEMP:
    //pdf.save(`${new Date().toISOString()}.pdf`);
    //return;

    return pdf.output('datauristring');      
  }

  private renderLeftMenu() : JSX.Element
  {
    return <React.Fragment>      
      <div className={styles.leftMenuHeading}>Signaturforklaringer</div>
      <List items={this.state.layers.filter(d => d.enabled)} onRenderCell={this.onRenderLayerListItem.bind(this)} />
      <div className={styles.leftMenuItem}>
        <div className={styles.signaturDrawingObjects} >
          <div data-is-focusable={true} title={"Opgave GPS-punkt"}>                    
            <div>{'Opgave GPS-punkt'}</div>
            <img src="images/marker-icon-gold.png" />
          </div>
          {this.state.step == DocumentationSteps.Documentation &&
            <div data-is-focusable={true} title={"Stak"}>                    
              <div>{'Stak'}</div>
              <img src="images/HD-Icon-Stack.png" />
            </div>
          }
          <div data-is-focusable={true} title={"Containerplads"}>                    
            <div>{'Containerplads'}</div>
            <img src="images/HD-Icon-Containerspot.png" />
          </div>
        </div>
      </div>
      <div className={styles.leftMenuItem} >
        <div data-is-focusable={true} title={"Vej tilladt/forbudt"}>  
          <div style={{display:"flex"}}>
            <div style={{width: "50%"}}>{'Vej tilladt'}</div>
            <div style={{width: "50%"}}>{'Vej forbudt'}</div>
          </div>           
          <div style={{display:"flex"}}>
            <div style={{backgroundColor:MapDrawingColors.RoadAllowed, width: "50%", height: "10px"}} />
            <div style={{backgroundColor:MapDrawingColors.RoadProhibited, width: "50%", height: "10px"}} />
          </div>       
        </div>
      </div>
      { this.state.step == DocumentationSteps.Documentation &&       
        <React.Fragment>
            <div className={styles.leftMenuHeading}>Arbejdsområder og linjer</div>        
            {this.state.mapData.workingAreas.map((v, i, a) => {
              return <div key={`wa_${i}`} style={{color:v.color == "transparent" ? "red" : v.color}} >{v.type == WorkingAreaType.WorkingArea ? "Arbejdsområde: " : "Linje: "}{v.name}</div>
            })}
        </React.Fragment>
      }
      {this.state.step == DocumentationSteps.StackMap && this.state.mapData.fixedTaskPosition &&  
        <React.Fragment>
          <div className={styles.leftMenuHeading}>Stakke</div>
          {this.state.mapData.stacks.map((v, i, a) => {
            return <div style={{marginBottom: "5px"}} key={`stack_${i}`}><img src="images/HD-Icon-Stack.png" /> {v.no}, {v.size} rm {v.treeSpecices}</div>
          })}
          <div className={styles.leftMenuHeading}>Opgave GPS-punkt</div>
          <Link 
            title="Klik for at åbne i Google Maps" 
            target="_blank" 
            href={`https://www.google.com/maps/search/?api=1&query=${this.state.mapData.fixedTaskPosition.position?.lat},${this.state.mapData.fixedTaskPosition.position?.lng}`}>
              <div>Koordinat: {this.state.mapData.fixedTaskPosition.position?.lat},{this.state.mapData.fixedTaskPosition.position?.lng}</div>
              <QRCode size={140} value={`https://www.google.com/maps/search/?api=1&query=${this.state.mapData.fixedTaskPosition.position?.lat},${this.state.mapData.fixedTaskPosition.position?.lng}`} />
          </Link>
        </React.Fragment>
      }
    </React.Fragment>
  }

  private onRenderLayerListItem(item?: ILayer, index?: number, isScrolling?: boolean): JSX.Element {    
    return (
      <div className={styles.leftMenuItem} >
        <div data-is-focusable={true} title={item?.name + ""}>                    
          <div>{item?.name + ''}</div>
          <img src={`/images/${item?.signature}`} />
        </div>
      </div>
    );
  }

  private onRenderLayerListItemNoImage(item?: ILayer, index?: number, isScrolling?: boolean): JSX.Element {    
    return (
      <div className={styles.leftMenuItem} >
        <div data-is-focusable={true} title={item?.name + ""}>                    
          <div>{item?.name + ''}</div>
          <div style={{backgroundColor:""}}></div>
        </div>
      </div>
    );
  }

  private renderFixedTaskPosition() : JSX.Element
  {
    if(!this.state.mapData.fixedTaskPosition)
    {
      return <React.Fragment />
    }

    return <Marker icon={MapUtilities.CreateFixedTaskPositionIcon(this.state.currentZoomLevel)} attribution={`fixedTaskPosition`} key={`fixedTaskPosition`} position={this.state.mapData.fixedTaskPosition.position} />
  }

  private renderStack(stack: IStackMarker) : JSX.Element
  {
    let icon : L.Icon<L.IconOptions> = MapUtilities.CreateStackIcon(this.state.currentZoomLevel, stack.tooltipPosition);

    return <Marker icon={icon} attribution={stack.identifier} key={stack.identifier} position={stack.position}>
        <Tooltip permanent={true} direction={stack.tooltipPosition ?? "top"} opacity={0.5}>
        <div>
            <div>Nr: {stack.no}</div>
            {this.state.step == DocumentationSteps.Documentation && 
              <React.Fragment>
                <div>{stack.size} rm</div>
                <div>{stack.treeSpecices}</div>
              </React.Fragment>
            }
          </div>
      </Tooltip>      
    </Marker>
  }

  private renderWorkingArea(workingarea : IWorkingArea) : JSX.Element
  {
    if(workingarea.type == WorkingAreaType.WorkingArea)
    {
      return <Polygon weight={this.lineOrBorderWeight} attribution={workingarea.identifier} key={workingarea.identifier} positions={workingarea.coordinates} opacity={0.7} fillColor={workingarea.color} color={workingarea.color == "transparent" ? "red" : workingarea.color} />
    } else
    {
      return <Polyline weight={this.lineOrBorderWeight} attribution={workingarea.identifier} key={workingarea.identifier} positions={workingarea.coordinates} opacity={0.7} fillColor={workingarea.color} color={workingarea.color == "transparent" ? "red" : workingarea.color} />
    }    
  }

  private renderRoad(road: IRoad) : JSX.Element
  {
    return <Polyline attribution={road.identifier} weight={this.lineOrBorderWeight} key={road.identifier} positions={road.coordinates} opacity={0.7} color={road.type == RoadType.Allowed ? MapDrawingColors.RoadAllowed : MapDrawingColors.RoadProhibited} />
  }

  private renderContainerSpot(containerSpot: IContainerSpot) : JSX.Element
  {
    return <Marker icon={MapUtilities.CreateContainerspotIcon(this.state.currentZoomLevel)} attribution={containerSpot.identifier} key={containerSpot.identifier} position={containerSpot.position} />
  }

  private renderTextArea(textArea: ITextArea) : JSX.Element
    {
      return <Marker icon={MapUtilities.CreateTextIcon(`<div style="display:inline-block; color:#fff; padding:5px; white-space:nowrap; opacity: 0.7; background-color:${textArea.color}">${textArea.name}</div>`)} attribution={textArea.identifier} key={textArea.identifier} position={textArea.position} />
    }
    
  private onZoomEnd()
  {
    var currentZoom = this.mapRef.leafletElement.getZoom(); 

    this.setState({currentZoomLevel: currentZoom});

    this.setMapBounds();
  }

  private getMapWidth()
  {
    return window.innerWidth * 0.95;
  }

  private handleResize()
  {
    this.setState({mapWidth: this.getMapWidth()});
  }
}