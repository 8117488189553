import { Icon, Label, Stack, TooltipHost } from "@fluentui/react";
import * as React from "react";

export interface ITooltipComponentProps
{
    name: string;
    description: string;
}

export class TooltipComponent extends React.Component<ITooltipComponentProps, {}> {
    public render(): React.ReactElement<ITooltipComponentProps> {
        return <Stack horizontal verticalAlign="center">
                    <Label>{this.props.name}</Label>
                    <TooltipHost 
                        content={this.props.description}>
                        <Icon
                        iconName='Info'                                
                        style={{marginLeft: 5, marginBottom: -3, cursor: "pointer" }}
                        />
                    </TooltipHost>
                </Stack>
    }
}