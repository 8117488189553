import React from 'react';
import { Route, Switch } from 'react-router';
import { AppLayout } from './components/AppLayout/AppLayout';
import { loadTheme, initializeIcons } from '@fluentui/react';
import Dashboard from './components/Dashboard/Dashboard';
import AnnouncementManagement from './components/Announcements/AnnouncementManagement';
import UserManagement from './components/UserManagement/UserManagement';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Login from './components/Login/Login';
import { BrowserRouter } from 'react-router-dom';
import { IUser } from './models/IUser';
import { IRegionDefaultPosition, IRoleDTO } from './models';

interface IAppState
{
  user?: IUser;
}

const theme = {
  themePrimary: '#4d6074',
  themeLighterAlt: '#eff6fc',
  themeLighter: '#deecf9',
  themeLight: '#c7e0f4',
  themeTertiary: '#71afe5',
  themeSecondary: '#2b88d8',
  themeDarkAlt: '#39444f',
  themeDark: '#39444f',
  themeDarker: '#004578',
  neutralLighterAlt: '#faf9f8',
  neutralLighter: '#f3f2f1',
  neutralLight: '#edebe9',
  neutralQuaternaryAlt: '#e1dfdd',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c6c4',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff',
}

export default class App extends React.Component<{}, IAppState> {
  static displayName = App.name;

  constructor(props : any) {
    super(props);
    
    this.state = {

    }

    this.init();
  }

  private init() {
      initializeIcons();
      loadTheme({
          palette: theme
      });
  }

  public render(): React.ReactElement<{}> {    
    const token = this.getToken();

    if(!token) {
      return <AppLayout>
          <Login setToken={(token) => { this.setToken(token); }} />
        </AppLayout>
    } 

    return (
      <AppLayout>        
        <BrowserRouter>
        <Switch>      
          <Route exact path="/">
            <Dashboard />
            </Route>    
          <Route path="/brugeradministration">
            <UserManagement />
          </Route>  
          <Route path="/beskeder">
            <AnnouncementManagement />
          </Route>     
          <Route path="/skift-adgangskode">
            <ChangePassword />
          </Route>               
        </Switch>
       </BrowserRouter>
      </AppLayout>
    );
  }
  static IsAuthenticated: boolean = false;
  static UserRoles: IRoleDTO[] = [];
  static CurrentUserRegionDefaultPosition: IRegionDefaultPosition;
  static Username : string = "";
  static SupplierId : string = "";

  private setToken(user: IUser)
  {
    localStorage.setItem('hd_token', JSON.stringify(user));
    this.setState({user:user});
  }

  private getToken() : string | null
  {
    const tokenString = localStorage.getItem('hd_token') as string;
    const user = JSON.parse(tokenString) as IUser;

    if(user == null)
    {
      return null;
    }

    App.IsAuthenticated = true;    
    App.Username = user?.name;
    App.SupplierId = user?.supplierId;
    App.UserRoles = user.roles;
    App.CurrentUserRegionDefaultPosition = user.regionDefaultPosition;
    return user?.token;
  }

  public static IsUserInRole(role: string) : boolean
  {
    if(!App.UserRoles)
    {
      return false;
    }

    return App.UserRoles.filter(d => d.name.indexOf(role) > -1).length == 1;
  }
}
