import React from 'react';
import { PrimaryButton, DefaultButton, Panel, PanelType, Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { IAssignmentDTO, IRegionDTO } from '../../../models';
import styles from './DelegatePanel.module.scss';
import { IAssignmentService, Services, AssignmentServiceProvider, UserServiceProvider, IUserService } from '../../../services';

interface IDelegatePanelProps
{
    selectedAssignment?: IAssignmentDTO;
    onDismiss(): void;
    onChanged(): void;
}

interface IDelegatePanelState
{
    canSubmit: boolean;
    regions: IRegionDTO[];
    currentItem: IAssignmentDTO;
}

export default class DelegatePanel extends React.Component<IDelegatePanelProps, IDelegatePanelState> {    
    private assignmentService : IAssignmentService;
    private userService : IUserService;
    
    constructor(props: IDelegatePanelProps) {
        super(props);
        
        this.assignmentService = Services.AssignmentService.Initialize(new AssignmentServiceProvider());
        this.userService = Services.UserService.Initialize(new UserServiceProvider());

        this.state = {
            canSubmit: false,
            regions: [] as IRegionDTO[],
            currentItem: props.selectedAssignment ? props.selectedAssignment : { },
        }

        this.load();
    }

    public render(): React.ReactElement<IDelegatePanelProps> {
        if(!this.state.currentItem)
        {
            return <div />
        }

        return (
            <Panel
              headerText={`Deleger internt: ${this.state.currentItem.orderName}`}
              isOpen={true}     
              className={styles.createPanel}                 
              isLightDismiss={false}              
              onRenderFooterContent={() => {
                  return (<div>                      
                        <React.Fragment>
                             <PrimaryButton  
                                    className={styles.button}
                                    onClick={() => {this.onUpdateAssignment()}}
                                    >
                                    Deleger videre
                                </PrimaryButton>                 
                        <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>
                    </React.Fragment>
                    </div>);            
                }
              }
              onDismiss={() => this.props.onDismiss()}              
              closeButtonAriaLabel="Luk panel"
              type={PanelType.medium}>
                <Stack>                    
                <Dropdown
                        required={true}
                        placeholder="Vælg fra listen"
                        label="Region"
                        selectedKey={this.state.currentItem.regionID}                        
                        options={this.state.regions ? this.state.regions.map((value : IRegionDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, regionID: this.state.regions?.filter(d => d.id == option?.key)[0].id }}); }}                        
                    />                     
                </Stack>
            </Panel>
        );
    }

    private async load()
    {         
        let regions = await this.userService.GetRegions();

        this.setState({regions: regions});
    }

    private async onUpdateAssignment()
    {
        let result : boolean = await this.assignmentService.UpdateAssignment(this.state.currentItem);
        
        if(result)
        {
            this.props.onChanged();
        } else
        {
            alert("Failed");
        }
    }
}