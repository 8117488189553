import * as React from 'react';
import { Dialog, DialogFooter, Label, PrimaryButton, TextField } from '@fluentui/react';
import { ITextArea, IWorkingArea } from '../../../../models';
import styles from './TextAreaDialog.module.scss';

export interface ITextAreaDialogProps {
    currentTextArea: ITextArea;
    onSaveClicked(identifier: string, name: string, color: string): void;
}

export interface ITextAreaDialogState {
    name: string;
    color: string;
    colors: string[];
}

export default class TextAreaDialog extends React.Component<ITextAreaDialogProps, ITextAreaDialogState> {

    constructor(props: ITextAreaDialogProps) {
        super(props);
        
        this.state = {
            name: props.currentTextArea.name ? props.currentTextArea.name + "" : "",
            color: props.currentTextArea.color ? props.currentTextArea.color + "" : "#4d6074",
            colors: ["#16a085", "#2ecc71", "#2980b9", "#3498db", "#9b59b6", "#e74c3c", "#f39c12", "#f1c40f", "#2c3e50", "#7f8c8d", "#bdc3c7", "#ecf0f1"] as string[]
        };
    }
    
    public render(): React.ReactElement<ITextAreaDialogProps> {  
        return (<Dialog
            hidden={false}
            minWidth={300}            
            title="Angiv tekst">              
              <TextField label="Tekst" value={this.state.name} required={true} onChange={(ev, newValue) => { this.setState({name: newValue + ""}); }} />              
              <Label>Flade farve</Label>
              <div className={styles.colorPickerContainer}>
                {this.state.colors.map((val, i, a ) => {
                  return <div className={styles.colorBox} style={{backgroundColor: val, border: this.state.color == val ? "2px solid rgba(0,0,0, 0.25)" : "none"}} onClick={() => { this.setState({color: val}); }} />
                })}
              </div>                
              <DialogFooter>
            <PrimaryButton text="Gem på kortet" disabled={this.state.name == "" || this.state.color == ""} onClick={() => { this.props.onSaveClicked(this.props.currentTextArea.identifier, this.state.name, this.state.color) } } />
          </DialogFooter>
          </Dialog>);
    }
}