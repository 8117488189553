import React from 'react';
import { PrimaryButton, DefaultButton, Selection, Panel, PanelType, elementContainsAttribute, Stack, TextField, Dropdown, IDropdownOption, Toggle, DetailsList, IColumn, Label, ICommandBarItemProps, CommandBar, SelectionMode, IObjectWithKey, Pivot, PivotItem } from '@fluentui/react';
import styles from './SupplierPanel.module.scss';
import { ICreateOrUpdateUserDTO, IRoleDTO, ICreateOrUpdateSupplierDTO, ISBPPersonDTO, ICreateSBPPersonDTO, IRegionDTO, ICertificationDTO } from '../../../models';
import { IUserService, Services, UserServiceProvider } from '../../../services';
import UserPanel, { UserPanelCloseReason } from '../UserPanel/UserPanel';

interface ISupplierPanelProps
{
    selectedItem? : ICreateOrUpdateSupplierDTO;
    onDismiss(): void;
    onChanged(user: ICreateOrUpdateSupplierDTO): void;
}

interface ISupplierPanelState
{
    currentItem : ICreateOrUpdateSupplierDTO;
    canSubmit: boolean;
    SBP_Persons?: ISBPPersonDTO[];
    selectedSBPPerson?: ISBPPersonDTO;
    newSBPPersonName?: string;
    showNewSBPPersonField: boolean;
    regions?: IRegionDTO[];
    certifications?: ICertificationDTO[];
    users?: ICreateOrUpdateUserDTO[];

    selectedUser?: ICreateOrUpdateUserDTO;    
    showUserPanel: boolean;
}

export default class SupplierPanel extends React.Component<ISupplierPanelProps, ISupplierPanelState> {        
    private userService : IUserService;
    private _selection_Users: Selection;
    private _selection: Selection;
    
    constructor(props: ISupplierPanelProps) {
        super(props);

        this.userService = Services.UserService.Initialize(new UserServiceProvider());
        
        this._selection_Users = new Selection({
            onSelectionChanged: () => {                 
                this.setState({ selectedUser: (this._selection_Users.getSelection() as IObjectWithKey | any)[0] as ICreateOrUpdateUserDTO});
              }
        });

        this._selection = new Selection({
            onSelectionChanged: () => {                 
                this.setState({ selectedSBPPerson: (this._selection.getSelection() as IObjectWithKey | any)[0] as ISBPPersonDTO});
              }
        });

        this.state = {
            canSubmit: true,
            currentItem: props.selectedItem ? props.selectedItem : { id: "", name: "", isCertified: false, certifications: [], internal: false } ,
            users: this.props.selectedItem?.users,
            showNewSBPPersonField: false,  
            showUserPanel: false          
        };

        this.load();
    }

    public render(): React.ReactElement<ISupplierPanelProps> {
        const CommandBar_Users: ICommandBarItemProps[] = [
            {
                key: 'newUser',
                text: 'Ny bruger',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({selectedUser: undefined, showUserPanel: true }) }      
            },
            {
                key: 'editUser',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({showUserPanel: true}) },
                disabled: !this.state.selectedUser
            }
        ];

        const CommandBar_SBPPersons: ICommandBarItemProps[] = [
            {
                key: 'newSBPPerson',
                text: 'Ny person',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({showNewSBPPersonField: true }) }      
            },
            {
                key: 'deleteSBPPerson',
                text: 'Slet',
                iconProps: { iconName: 'Delete' },
                onClick: () => { this.deleteSBPPerson(); },
                disabled: !this.state.selectedSBPPerson
            }
        ];
        
        return (
            <Panel
              headerText={this.props.selectedItem ? "Rediger leverandør" : "Opret ny leverandør"}
              isOpen={true}  
              isBlocking={true}   
              className={styles.userPanel}                 
              isLightDismiss={false}              
              onRenderFooterContent={() => {
                  return (<div>
                    <PrimaryButton disabled={!this.state.canSubmit} style={{marginRight:"10px"}} 
                        onClick={() => {this.onAddOrUpdateAssignment()}}
                        >
                        {this.props.selectedItem ? "Gem" : "Opret"}
                    </PrimaryButton>
                    { this.props.selectedItem && 
                        <PrimaryButton 
                            className={styles.deleteButton} 
                            onClick={async () => { this.deleteSupplier(); } }
                            >
                            Slet leverandør
                        </PrimaryButton> 
                    }
                    <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>
                </div>);                
                }
              }
              onDismiss={() => this.props.onDismiss()}              
              closeButtonAriaLabel="Luk leverandørpanel"
              type={PanelType.large}
            >                
                <Stack>                    
                    <TextField 
                        required={true}                         
                        label="Navn" 
                        placeholder="Angiv navnet på leverandøren" 
                        autoComplete="off_navn"
                        value={this.state.currentItem.name} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, name: newValue ? newValue : "" }}); }}
                    />                    
                    <TextField 
                        required={false}                         
                        label="Projektnummer" 
                        placeholder="Angiv projektnummer på leverandøren" 
                        autoComplete="off_navn"
                        value={this.state.currentItem.projectNo} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, projectNo: newValue ? newValue : "" }}); }}
                    />
                    <Dropdown
                        required={false}
                        placeholder="Vælg fra listen"
                        multiSelect={true}
                        label="Certificeret"
                        selectedKeys={this.state.currentItem.certifications?.map(d => d.id)}                        
                        options={this.state.certifications ? this.state.certifications.map((value : ICertificationDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.onCertificationsChanged(event, option) }}                        
                    />
                    <Dropdown
                        required={true}
                        placeholder="Vælg fra listen"
                        label="Region"
                        selectedKey={this.state.currentItem.region?.id}                        
                        options={this.state.regions ? this.state.regions.map((value : IRegionDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, region: this.state.regions?.filter(d => d.id == option?.key)[0] }}); }}                        
                    />
                    <Toggle label="Intern HD leverandør" checked={this.state.currentItem.internal ? this.state.currentItem.internal : false} onChange={(ev, checked? : boolean) => { this.setState({ currentItem: { ...this.state.currentItem, internal: checked ? checked : false }}); }} />
                    {this.props.selectedItem && 
                    <Pivot>                        
                        <PivotItem key="sbpPersons" headerText={`SBP uddannede personer`}>
                            <CommandBar items={CommandBar_SBPPersons} styles={{root: {paddingLeft:"0px"}}} />
                                {this.state.showNewSBPPersonField && 
                                    <div>
                                        <TextField 
                                        label="Navn på SBP" 
                                        placeholder="Navn på SBP" 
                                        value={this.state.newSBPPersonName} 
                                        onChange={(ev, newValue) => { this.setState({newSBPPersonName: newValue} ); }}
                                        />
                                        <PrimaryButton disabled={!this.state.newSBPPersonName} 
                                            onClick={() => {this.addNewSBPPerson()}}
                                            >
                                            Tilføj
                                        </PrimaryButton>
                                    </div>
                                }
                                <DetailsList 
                                    items={this.state.SBP_Persons ? this.state.SBP_Persons : []} 
                                    columns={this.Columns_SBPPersons}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                />
                        </PivotItem>
                        <PivotItem key="users" headerText={`Brugere`}>
                            <CommandBar items={CommandBar_Users} styles={{root: {paddingLeft:"0px"}}} />
                            <DetailsList 
                                    items={this.state.users ? this.state.users : []} 
                                    columns={this.Columns_Users}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection_Users}
                                />
                            {this.state.showUserPanel && <UserPanel selectedSupplier={this.state.currentItem} selectedUser={this.state.selectedUser} onChanged={(user: ICreateOrUpdateUserDTO, reason) => 
                            { 
                                let users = [...this.state.users as ICreateOrUpdateUserDTO[]];

                                if(reason == UserPanelCloseReason.AddOrUpdate)
                                {
                                    if(user.id == undefined) // New user
                                    {                                         
                                        users.push(user);
                                    } else
                                    {                                        
                                        let i = users.findIndex(d => d.id == user.id);
                                        users[i] = user;
                                    }
                                } else if(reason == UserPanelCloseReason.Delete)
                                {
                                    let i = users.findIndex(d => d.id == user.id);
                                    users.splice(i, 1);
                                }
                                
                                this.setState({users: users, showUserPanel: false});
                            }} onDismiss={() => { this.setState({showUserPanel: false}); } } />}
                        </PivotItem>
                    </Pivot>
                    }
                </Stack>
            </Panel>
        );
    }

    private async load()
    {
        let regions = await this.userService.GetRegions();
        let certifications = await this.userService.GetAllCertifications();

        //Filter out "No"-option
        certifications = certifications.filter(d => d.id != "6a865d11-a949-4658-9872-ea79a7045944");

        if(this.props.selectedItem)
        {
            let SBP_Persons = await this.userService.GetSBPPersons(this.props.selectedItem.id);
            
            this.setState({ SBP_Persons:SBP_Persons, regions: regions, certifications: certifications });
        } else
        {
            this.setState({ regions: regions, certifications: certifications });
        }
    }

    private onCertificationsChanged(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption)
    {
        if(item)
        {
            let certifications = item.selected ? [...this.state.currentItem.certifications, { id: item.key, name: item.text }] as ICertificationDTO[] : this.state.currentItem.certifications.filter(key => key.id !== item.key) as ICertificationDTO[]

            this.setState({ currentItem: { ...this.state.currentItem, certifications: certifications }});
        }
    }

    private async addNewSBPPerson()
    {
        let result = await this.userService.CreateSBPPerson({ supplierId: this.state.currentItem.id, name:  this.state.newSBPPersonName} as ICreateSBPPersonDTO);
        if(result)
        {
            this.setState({newSBPPersonName: undefined, showNewSBPPersonField: false}, () => {
                this.load();

            });
        } else
        {
            alert("Fejl ved oprettelse af SBP person.");
        }
    }

    private async deleteSBPPerson()
    {
        let result = await this.userService.DeleteSBPPerson(this.state.selectedSBPPerson);
        if(result)
        {
            this.load();
        } else
        {
            alert("Fejl ved sletning af SBP person"); //TODO
        }
    }

    private async onAddOrUpdateAssignment()
    {
        let result : boolean = false;
        if(this.props.selectedItem)
        {
            result = await this.userService.UpdateSupplier(this.state.currentItem);
        } else
        {
            result = await this.userService.CreateSupplier(this.state.currentItem);
        }

        if(result)
        {
            this.props.onChanged(this.state.currentItem);
        } else
        {
            alert("Fejl ved oprettelse eller opdatering af leverandør. Prøv igen senere.");
        }
    }

    private async deleteSupplier()
    {
        if(window.confirm("Er du sikker på, at du ønsker at slette denne leverandør? Alle tilknyttede brugere og SBP personer slettes også!"))
        {
            let result : boolean = await this.userService.DeleteSupplier(this.state.currentItem);
         
            if(result)
            {
                this.props.onChanged(this.state.currentItem);
            } else
            {
                alert("Der er sket en fejl ved sletning af leverandøren. Prøv igen senere.");
            }
        }        
    }

    private Columns_SBPPersons: IColumn[] = [
        {
          key: 'name',
          name: 'Navn',
          fieldName: 'name',
          minWidth: 200,
          maxWidth: 200
        }
      ];

      private Columns_Users: IColumn[] = [
        {
          key: 'name',
          name: 'Navn',
          fieldName: 'name',
          minWidth: 200,
          maxWidth: 200,
          isResizable: true,
        },
        {
            key: 'email',
            name: 'E-mail',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
        }
    ];
}