import React from 'react';
import { DefaultButton, Link, MessageBar, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { IUserService, Services, UserServiceProvider } from '../../services';
import styles from './ChangePassword.module.scss';
import { IChangePasswordDTO } from '../../models';

interface IChangePasswordProps
{
}

interface IChangePasswordState
{
    existingPassword?: string;
    newPassword?: string;
    newPasswordAgain?: string;
    canSubmit: boolean;
}

export default class ChangePassword extends React.Component<IChangePasswordProps, IChangePasswordState> {
  private userService : IUserService;

  constructor(props: IChangePasswordProps) {
    super(props);
    
    this.userService = Services.UserService.Initialize(new UserServiceProvider());

    this.state = {      
        canSubmit: false
    };
  }

    public render(): React.ReactElement<IChangePasswordProps> {
      return (<div className={styles.changePassword}>
          <h3>Skift adgangskode</h3>
            <TextField 
                className={styles.marginBottom}
                required={false} 
                placeholder={"Angiv din nuværende adgangskode" }
                type="password" 
                autoComplete="new-password"
                canRevealPassword 
                revealPasswordAriaLabel="Vis adgangskode"
                value={this.state.existingPassword} 
                onChange={(ev, newValue) => { this.setState({ existingPassword: newValue }, () => { this.validateForm() }); }}
            />    
            <TextField 
                className={styles.marginBottom}
                required={false} 
                placeholder={"Angiv din nye adgangskode" }
                type="password" 
                autoComplete="new-password"
                disabled={this.state.existingPassword == undefined || this.state.existingPassword == ""}
                canRevealPassword 
                revealPasswordAriaLabel="Vis adgangskode" 
                value={this.state.newPassword} 
                onChange={(ev, newValue) => { this.setState({ newPassword: newValue }, () => { this.validateForm() }); }}
            />    
            <TextField 
                className={styles.marginBottom}
                required={false} 
                placeholder={"Angiv din nye adgangskode igen" }
                validateOnLoad={false}
                type="password" 
                autoComplete="new-password"
                disabled={this.state.existingPassword == undefined || this.state.existingPassword == ""}
                canRevealPassword 
                revealPasswordAriaLabel="Vis adgangskode" 
                value={this.state.newPasswordAgain} 
                onGetErrorMessage={this.validateChangePassword.bind(this)}
                onChange={(ev, newValue) => { this.setState({ newPasswordAgain: newValue }); }}
            />   
            { this.state.canSubmit && 
                <MessageBar            
                    className={styles.marginBottom}
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}>
                        Når du skifter din adgangskode bliver du automatisk logget af Flisportalen.
                </MessageBar>
            }
            <PrimaryButton disabled={!this.state.canSubmit} onClick={() => {this.onChangePassword(); }}>Skift adgangskode</PrimaryButton> 
        </div>)
    }

    private async onChangePassword()
    {
        let result : boolean = await this.userService.ChangePassword({ existingPassword: this.state.existingPassword, newPassword: this.state.newPassword } as IChangePasswordDTO);
        
        if(result)
        {
            this.userService.SignOut();
        } else
        {
            alert("Der er sket en fejl. Prøv venligst igen senere.");
        }
    }

    private validateChangePassword()
    {        
        let isValid : boolean = false;

        if(!this.state.newPassword || !this.state.newPasswordAgain)
        {
            isValid = false;
        } else
        {
            isValid = this.state.newPassword === this.state.newPasswordAgain;
        }

        this.setState({canSubmit: isValid });

        return isValid ? "" : "Din nye adgangskode er ikke angivet ens.";
    }

    private validateForm()
    {
        if(this.state.existingPassword == undefined || this.state.existingPassword == "")
        {
            this.setState({canSubmit: false });
        } else
        {
            this.validateChangePassword();
        }
    }
}